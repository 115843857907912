import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";
import { Navbar, Nav, NavDropdown, FormControl, Form, Button, Dropdown, Spinner } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import FileSaver from "file-saver";
import { useForm } from "react-hook-form";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import './index.scss';
import { SortByAlpha } from '@material-ui/icons';
const fs = require('fs');
const AWS = require('aws-sdk');


function Media() {
  const location = useLocation();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const randNumber = Math.floor(Math.random() * 1000000000)
  const user_token = JSON.parse(localStorage.getItem("user_token"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));


  // const ID = process.env.REACT_APP_S3_API_KEY;
  // const SECRET = process.env.REACT_APP_S3_SECRET_KEY;
  // const BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_MEDIA;

  // const s3 = new AWS.S3({
  //   accessKeyId: ID,
  //   secretAccessKey: SECRET
  // });
  // const params = {
  //   Bucket: process.env.REACT_APP_S3_BUCKET_MEDIA,
  //   CreateBucketConfiguration: {
  //     LocationConstraint: process.env.REACT_APP_S3_REGION
  //   }
  // };

  // const [selectedFile, setFiletoUpload] = React.useState("");
  // const [getStatus, setStatus] = React.useState("");
  // const [isLoading, setLoading] = React.useState(false)
  // const [isLoadingD, setLoadingD] = React.useState(false)
  // const [isLoadingl, setLoadingl] = React.useState(false)
  // const size = new URLSearchParams(location.search).get('size')
  // const pageSize = (size === null ? 24 : size)
  // const [getData, setData] = React.useState([]);


  // var paramsGet = {
  //   Bucket: process.env.REACT_APP_S3_BUCKET_MEDIA,
  //   Prefix: `merchant/media/${store_id}`,
  //   MaxKeys: pageSize
  // };
  // async function getProfile() {
  //   setLoadingl(true);
  //   s3.listObjectsV2(paramsGet, function (err, data) {
  //     if (data) {
  //       setData(data.Contents)
  //     }
  //     else {
  //       setData('error')
  //       setLoadingl(false);
  //     }
  //     setLoadingl(false);
  //   });
  // }
  // useEffect(() => {
  //   getProfile();
  // }, [size]);



  // const uploadFile = (fileName) => {
  //   setLoading(true);
  //   let newArr = fileName.mediaFile;
  //   for (let i = 0; i < newArr.length; i++) {
  //     const imageUrl = newArr[i].name.replace(/ /g, "_");
  //     const params = {
  //       Bucket: process.env.REACT_APP_S3_BUCKET_MEDIA,
  //       Key: `merchant/media/${store_id}/${randNumber}${imageUrl}`,
  //       Body: newArr[i],
  //       ContentType: "images",
  //       ACL: 'public-read'
  //     };
  //     s3.upload(params, function (err, data) {
  //       if (data) {
  //         setStatus('success')
  //         setData([data, ...getData]);
  //         getProfile();
  //         setLoading(false);
  //       }
  //       if (err) {
  //         setStatus('error')
  //         setLoading(false);
  //       }
  //     });
  //   };
  // };

  // const sortedDates = getData && getData.sort((dateA, dateB) => dateB.LastModified - dateA.LastModified)

  const displayMessage = () => {
    return (
      <div className="empty-message">
          <h4>The Media Library will be available soon.</h4>
      </div>
    )
  }

  return (
    <div className="mediaSyle">
      <div className="container">
        <div className="row newStore">
        <div className="col-md-5">
            {displayMessage()}
            </div>
          <div className="col-md-12">
            {/* <div className="storeCardH BrdrRound"> */}


            {/* {isLoadingl ? <div className="dcLoader1">&nbsp;</div> : <div style={{ "border": "3px solid #fff" }}></div>} */}
            {/* <div className="storeCardB">
                {getStatus === 'success' ?
                  <div className="msgCneter">
                    <small className="successHeadng">File has been added successfully.</small>
                  </div>
                  : null}
                {getStatus === 'error' ?
                  <div className="msgCneter">
                    <h5 className="errorHeadng">Error in uploading media file..</h5>
                  </div>
                  : null}
                <h4 className="txtcntr">Media</h4>
                <form onSubmit={handleSubmit(uploadFile)}>
                  <div className="row newStore">
                    <div className="col-md-4">
                      <div className="form-group">
                        <input multiple type="file" accept=".jpeg,.jpg,.png,.PNG,.JPEG,.JPG" className="form-control fileUpload"
                          onChange={(e) => setFiletoUpload(e.target.file)} id="mediaFile"
                          aria-invalid={errors.firstName ? "true" : "false"}
                          {...register('mediaFile', { required: true })} />
                      </div>
                    </div>
                    <div className="txtcntr">
                      {isLoading ? <Button className="hghtbtn" disabled>Uploading...</Button> :
                        <Button className="hghtbtn" type="submit">Upload</Button>
                      }
                    </div>
                  </div>
                </form>
                <div className="row">
                  {getData && getData === 'error' ?
                    <div className="col-md-4">
                      <div className="storeCard">
                        <div>
                          <h5 className="errorHeadng">Not Found</h5>
                        </div>
                      </div>
                    </div>
                    : null}
                  {sortedDates.map((item, i) =>
                    <div className="col-md-2 Imgmain" key={i}>
                      <div className="imgDisp">
                        <img src={`https://merchant-store-media.s3.amazonaws.com/${item.Key}`} className="imgDispimg"></img>
                        {(i)}
                        <div className="manageButtonDF">
                          <CopyToClipboard text={`https://merchant-store-media.s3.amazonaws.com/${item.Key}`}>
                            <Button variant="secondary" size="sm" title="copy link" className="manageButtonC">
                              <small className="dFlex">Copy URL&nbsp; <FileCopyIcon className="copyIcon" /></small></Button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="loadMore">
                  {isLoadingl ? <Button size="sm" variant="info" disabled>Loading...</Button> :
                    <Link to={{ pathname: "/media", search: `?size=${Number(pageSize) + 24}` }}>
                      <Button size="sm" variant="info">Load More</Button>
                    </Link>
                  }
                </div>

              </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Media;
