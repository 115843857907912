import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, FormControl, Form, Button, Dropdown, Spinner } from 'react-bootstrap';
import { CSVLink, CSVDownload } from 'react-csv';
import FileSaver from "file-saver";
import './index.scss';
import axios from "axios";
import Navigation from '../Products/Navigation';
const fs = require('fs');
const AWS = require('aws-sdk');
const JSZip = require("jszip");


function BulkUpload() {
  const user_token = JSON.parse(localStorage.getItem("user_token"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));
  const csvData = [
    ['UID_SKU_number', 'title', 'price_in_local_currency', 'image_urls', 'description', 'features', 'category', 'weight_grams', 'in_stock', 'estimated_delivery_days', 'quantity', 'inventory_location', 'brand_name'],
    ['DC1234', 'Product Name', '150', 'img1,img2,img3', 'Product Description', 'Feature1,Features2,Feature3', 'Products Category', '100', 'true/false', '5', '10', 'UAE', 'Brand Name']
  ];

  const BUCKET_NAME = process.env.REACT_APP_S3_BUCKET;

  const [selectedFile, setFiletoUpload] = React.useState("");
  const [getStatus, setStatus] = React.useState("");
  const [isLoading, setLoading] = React.useState(false)
  const randNumber = Math.floor(Math.random() * 1000000000)
  const [getStatusApi, setHttpStatus] = React.useState("");
  const [isLoadingApi, setLoadingApi] = React.useState(false)

  const [getResponseApi, getResponse] = React.useState("");
  const [getResponseError, setResponseError] = React.useState("");

  const uploadFile = (fileName) => {
    setLoading(true);
    const zip = new JSZip();
    zip.file(`products-${store_id}-${randNumber}.csv`, fileName);
    zip.generateAsync({ type: "blob" }).then(content => {


      fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/store_product_lists/pre_signed_url.json?bucket=${BUCKET_NAME}&key=merchants/products/${store_id}/uploaded_csv/products-${store_id}-${randNumber}.zip`, {
        method: 'GET',
        headers: {
          'Accept': 'application/vnd.api+json; version:3.0',
          'X-User-Id': user_id,
          'X-User-Token': user_token,
          'X-Store-Id': store_id,
          'X-Store-Token': store_token,
          'Content-Type': 'application/json'
        }
      }).then((result) => {
        if (result.status === 200) {
          result.json().then((resp) => {
            fetch(resp.url, {
              method: "PUT",
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              body: content
            }).then((result) => {

              if (result.status === 200) {
                setStatus('success')
                setLoadingApi(true);
                fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/store_product_lists?file_url=merchants/products/${store_id}/uploaded_csv/products-${store_id}-${randNumber}.zip`, {
                  method: "POST",
                  headers: {
                    'Accept': 'application/vnd.api+json; version:3.0',
                    'X-User-Id': user_id,
                    'X-User-Token': user_token,
                    'X-Store-Id': store_id,
                    'X-Store-Token': store_token,
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify()
                }).then((result) => {
                  result.json().then((resp) => {
                    setHttpStatus(result.status);
                    if (resp.errors && !result.error) {
                      getResponse(resp.errors);
                    }
                    if (result.error && !resp.errors) {
                      setResponseError(result.error);
                    }
                    setLoadingApi(false)
                  })
                })
              } else {
                setStatus('error')
              }
              setLoading(false);
            })
          })
        } else {
          setLoading(false);
        }
      }, [])
    });
  };

  return (
    <div className="bulkUpload orderStyle">
      <div className="container">
        <div className="row cardList__margin">
          <div className="col-md-12">
            <div className="Table-header">
              <Navigation />
              <div className="updateButton">
                <h5 className="listHeadng">Upload CSV</h5>
              </div>
            </div>
            <div className="new-bulk-section">
              <div className="row newStore">
                <div className="col-md-6">
                  <div className="csForcard BrdrRound">
                    <div className="hrd_blkupld">
                      <div className="csDownld">
                        <CSVLink
                          data={csvData}
                          filename={"DC_CSV_SAMPLE.csv"}
                          target="_blank"
                        >
                          <Button variant="warning" size="sm">Download CSV Sample</Button>
                        </CSVLink>
                      </div>
                    </div>
                    <div className="msgList">
                      <div>
                        <p className="infosg">
                          All fields in the CSV file should be filled as per the sample file. No field should be left blank
                        </p>
                        <p className="infosg">
                          Image urls cannnot be broken and should start with "https://"
                        </p>
                        <p className="infosg">
                          Each item in the column of "UID_SKU_number" should be unique
                          and not to be repeated.
                        </p>
                        <p className="infosg">
                          "Price in Local Currency" column should not be 0.
                        </p>
                        <p className="infosg">
                          The CSV file uploaded should be in the CSV UTF-8 (Comma
                          delimited) (.csv) format.
                        </p>
                        <p className="infosg">
                          "Price in Local Currency", "Weight in Grams", "Estimated
                          Delivery in Days" and "Quantity" entries should only be in
                          digits and not contain alphabets.
                        </p>
                        <p className="infosg">
                          If you wish to add multiple entries in the "Features"
                          column, each feature should be separated with a ","
                        </p>
                        <p className="infosg">
                          If you wish to add multiple entries in the "images_urls"
                          column, each image url should be separated with a ","
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="csvForcard BrdrRound">
                    <div className="DisMsg">
                      {getStatus === "error" ? (
                        <div className="Almsg">
                          <h5 className="alert alert-danger">Error in processing the CSV.</h5>
                        </div>
                      ) : null}
                      {getStatusApi === 200 ? (
                        <div>
                          <h5 className="alert alert-success">
                            File has been added in queue successfully.
                          </h5>
                        </div>
                      ) : null}
                      {getStatusApi === 422 ? (
                        <div>
                          <h5 className="alert alert-danger">Error in processing the CSV.</h5>
                        </div>
                      ) : null}
                      {getStatusApi === 401 ? (
                        <div>
                          <h5 className="alert alert-danger">Error in processing the CSV.</h5>
                        </div>
                      ) : null}
                      {getStatusApi === 500 ? (
                        <div>
                          <h5 className="alert alert-danger">
                            Error in CSV format. Kindly check your CSV format.
                          </h5>
                        </div>
                      ) : null}
                    </div>

                    <p className="txtcntr">
                      CSV should be in CSV UTF-8 (Comma delimited) (.csv)
                    </p>

                    <div className="needHelp">
                      <Link to="./support">
                        <Button variant="info" size="sm">Need Help?</Button>
                      </Link>
                    </div>
                    <h4 className="txtcntr">Select CSV</h4>
                    <div className="row newStore">
                      <div className="col-md-8">
                        <div className="form-group">
                          <input
                            type="file"
                            accept=".csv"
                            className="form-control fileUpload"
                            onChange={(e) => setFiletoUpload(e.target.files[0])}
                          />
                        </div>
                      </div>
                      <div className="txtcntr">
                        <Button
                          className="hghtbtn"
                          onClick={() => uploadFile(selectedFile)}
                        >
                          Upload
                        </Button>
                      </div>
                    </div>

                    <div className="row newStore">
                      <div className="col-md-8 txtcntr">
                        {isLoading ? (
                          <div className="upldWtng">
                            <Spinner className="upldWtngSpi" animation="border" />
                            <p>Please wait... file is being added to the queue.</p>
                          </div>
                        ) : null}
                        {isLoadingApi ? (
                          <div className="upldWtng">
                            <Spinner className="upldWtngSpi" animation="border" />
                            <p>Please wait... validation may take a while. Do not close this page.</p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {getResponseApi && !getResponseError ? (
                      <div className="errorOver">
                        {getResponseApi.map((item, i) => (
                          <div className="errorMsgDis">{item}</div>
                        ))}
                      </div>
                    ) : null}
                    {getResponseError && !getResponseApi ? (
                      <div className="errorOver">
                        {getResponseError.map((item, i) => (
                          <div className="errorMsgDis">{item}</div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BulkUpload;
