import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import { Button, Spinner } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import logo from '../../dc-logo.png';
import './index.scss';


function Login() {
  const SITE_KEY = process.env.REACT_APP_DC_SITKEY;
  const recaptchaRef = React.useRef();
  // const onSubmitWithReCAPTCHA = async () => {
  //   const token = await recaptchaRef.current.executeAsync(); 
  // } 
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false)
  const [HttpStatus, setHttpStatus] = useState([]);

  const [getSuccessMsg, setSuccessMsg] = useState("");
  const [getSuccessMsgT, setSuccessMsgT] = useState("");
  const [getotpResponse, setotpResponse] = useState("");


  // useEffect(() => {
  //   const loadScriptByURL = (id, url, callback) => {
  //     const isScriptExist = document.getElementById(id);

  //     if (!isScriptExist) {
  //       var script = document.createElement("script");
  //       script.type = "text/javascript";
  //       script.src = url;
  //       script.id = id;
  //       script.onload = function () {
  //         if (callback) callback();
  //       };
  //       document.body.appendChild(script);
  //     }
  //     if (isScriptExist && callback) callback();
  //   }
  //   loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
  //   });
  // }, []);

  // function loginMerchant(loginData) {
  //   setLoading(true);
  //   window.grecaptcha.ready(() => {
  //     window.grecaptcha.execute(SITE_KEY, { action: 'Frontend_SignIn' }).then(token => {
  //       submitData(token, loginData);
  //     });
  //   });
  // }

  useEffect(() => {
    const timer = setTimeout(() => { setSuccessMsgT('') }, 4000);
    return () => clearTimeout(timer);
  }, [getSuccessMsgT]);
  // function loginMerchant(loginData) {
  const loginMerchant = async (loginData) => {
    const token = await recaptchaRef.current.executeAsync();
    let data = { "login": loginData.login, "referral_code": null, "sign_up_code": null, "recaptcha_token": token, "key": 1 }
    if (token) {
      setLoading(true);
      fetch(`${process.env.REACT_APP_DC_API}/api/sessions`, {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'HTTP-X-REAL-IP': '54.237.181.170',
          'REMOTE-ADDR': '54.237.181.170',
          'HTTP-X-FORWARDED-FOR': '54.237.181.170'
        },
        body: JSON.stringify(data)
      }).then((result) => {
        setHttpStatus(result.status);
        if (result.status === 201) {
          result.json().then((resp) => {
            setSuccessMsg("success")
            setSuccessMsgT("success")
            setotpResponse(resp.one_time_password)
            const timer =
              setTimeout(() => { setHttpStatusCon('') }, 0);
            setTimeout(() => { setHttpStatus('') }, 0);
            return () => clearTimeout(timer);
          })
          setLoading(false)
        }
        setLoading(false)
      })
    }
  }

  const [state, setState] = useState("");
  const handleOtpChange = (otp) => {
    setState(otp);
  };
  const [isLoadingCon, setLoadingCon] = useState(false)
  const [HttpStatusCon, setHttpStatusCon] = useState([]);
  function confirmOTP() {
    let data = { "one_time_password_id": getotpResponse.id, "code": state, "include": "authentication_token" }
    setLoadingCon(true);
    fetch(`${process.env.REACT_APP_DC_API}/api/one_time_password_authorizations`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'HTTP-X-REAL-IP': '54.237.181.170',
        'REMOTE-ADDR': '54.237.181.170',
        'HTTP-X-FORWARDED-FOR': '54.237.181.170'
      },
      body: JSON.stringify(data)
    }).then((result) => {
      setHttpStatusCon(result.status);
      if (result.status === 201) {
        result.json().then((resp) => {
          localStorage.setItem("user_token", JSON.stringify(resp.one_time_password.authentication_token.token));
          localStorage.setItem("user_id", JSON.stringify(resp.one_time_password.requestable_id));
          localStorage.setItem("user_email", JSON.stringify(resp.one_time_password.email));
          history.push('/dashboard');
        })
        setLoadingCon(false)
      }
      setLoadingCon(false)
    })
  }
  const tryDifferentEmail = () => {
    const timer =
      setTimeout(() => { setSuccessMsg('') }, 0);
    setTimeout(() => { setSuccessMsgT('') }, 0);
    setTimeout(() => { setHttpStatusCon('') }, 0);
    setTimeout(() => { setHttpStatus('') }, 0);
    return () => clearTimeout(timer);
  }

  let user_token = JSON.parse(localStorage.getItem('user_token'));
  let user_id = JSON.parse(localStorage.getItem('user_id'));

  return (
    <div className="loginStyle">
      {user_id && user_token ? <Redirect to="dashboard" /> : null}
      <div className="container">
        <div className="row loginRow">
          <div className="rowBox">
            <div className="loginHeader">
              <h3>Sign In or Sign Up</h3>
              <img src={logo} width="50px" />
            </div>
            <div>
              {HttpStatus === 401 ?
                <div>
                  <h5 className="alert alert-danger">Login credentials are invalid.</h5>
                </div>
                : null}
              {HttpStatusCon === 401 ?
                <div>
                  <h5 className="alert alert-danger">Login credentials are invalid.</h5>
                </div>
                : null}
              {HttpStatus === 422 ?
                <div>
                  <h5 className="alert alert-danger">Email ID used has been temporarily blocked. Try again at a later time or use a different email ID.</h5>
                </div>
                : null}
              {HttpStatusCon === 422 ?
                <div>
                  <h5 className="alert alert-danger">Invalid! Kindly enter the correct OTP.</h5>
                </div>
                : null}
              {HttpStatus === 429 ?
                <div>
                  <h5 className="alert alert-danger">Email ID used has been temporarily blocked. Try again at a later time or use a different email ID.</h5>
                </div>
                : null}
              {HttpStatusCon === 429 ?
                <div>
                  <h5 className="alert alert-danger">Too many request try after sometime</h5>
                </div>
                : null}
              {getSuccessMsgT === 'success' ?
                <div className="alert alert-success">OTP sent to {getotpResponse.email}, kindly check your inbox.</div>
                : null}
            </div>
            {getSuccessMsg ?
              <div>
                <form onSubmit={handleSubmit(confirmOTP)}>
                  <label className="lableLeft">Enter OTP</label>
                  <OtpInput
                    inputStyle="inputStyle"
                    containerStyle="otp"
                    value={state}
                    onChange={handleOtpChange}
                    numInputs={6}
                    separator={<div className="seperator"></div>}
                    id="code" />
                  {isLoadingCon ? <Button className="Button otpCon" variant="info" disabled>Please wait...</Button> : <Button className="Button otpCon" variant="info" type="submit" >Confirm</Button>}
                </form>
                <form onSubmit={handleSubmit(loginMerchant)}>
                  <input type="hidden" value={getotpResponse.email} id="login" />
                  <div className="btnToRD">
                    <Button onClick={tryDifferentEmail} variant="info" className="Button resendBtn">Try with different email ID</Button>
                    {isLoading ? <Button className="Button resendBtn" variant="info" disabled>Sending... &nbsp;<Spinner className="lgnSpin" animation="border" /></Button> : <Button className="Button resendBtn" variant="info" type="submit" >Resend OTP</Button>}
                  </div>
                </form>
              </div>
              :
              <form onSubmit={handleSubmit(loginMerchant)}>
                <div className="form-group">
                  <label className="lableLeft">Email Address</label>
                  <input className="form-control" type="email" id="login"
                    aria-invalid={errors.login ? "true" : "false"}
                    {...register('login', { required: true })}
                  />
                </div>
                <ReCAPTCHA
                  ref={recaptchaRef} size="invisible"
                  sitekey={SITE_KEY}
                />
                <br />
                {/* {getCaptchaValue ? <div> */}
                {isLoading ? <Button className="Button lgnSpinB" variant="info" disabled>Please wait...</Button> : <Button className="Button" variant="info" type="submit" >Sign In/Sign Up</Button>}
                {/* </div>
                  : <Button className="Button lgnSpinB" variant="info" disabled>Sign In/Sign Up</Button>} */}
                <p>Want to enter your password instead?</p>
                <p><Link to="/logins">Try our legacy sign in</Link></p>
              </form>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
