import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Dropdown, Modal } from 'react-bootstrap'
import Barcode from "react-hooks-barcode";
import ReactToPrint from "react-to-print";
import Navigation from "./Navigation";
import Pagination from "./Pagination";
import Titles from "./Titles";
import TableSkeleten from "./TableSkeleten"
import SelectAllIcon from '@material-ui/icons/SelectAll';
import './index.scss';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import SortIcon from '@mui/icons-material/Sort';
import { useGetAllOrdersQuery, useGetAllOrdersNavQuery, useAcceptOrderMutation, useRejectOrderMutation } from '../../apis/orders';
import SearchIcon from "@material-ui/icons/Search";
function PendingOrders(props) {
  const history = useHistory();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10
  const sort = new URLSearchParams(location.search).get('sort') || null
  const marketplace_shipping_state = new URLSearchParams(location.search).get('marketplace_shipping_state')
  const state = new URLSearchParams(location.search).get('state')
  const shipping_address_id_params = new URLSearchParams(location.search).get('initial_destination_id')
  const order_number = new URLSearchParams(location.search).get('order_id')
  let order_numbers;
  let states;
  let marketplace_shipping_states;
  let shipping_address_id;
  let onSort;
  let sortParams;
  if (order_number === null) {
    order_numbers = '';
  } else {
    order_numbers = `&order_id=${order_number}`
  }
  if (state === null) {
    states = '';
  } else {
    states = `&state=${state}`
  }
  if (marketplace_shipping_state === null) {
    marketplace_shipping_states = '';
  } else {
    marketplace_shipping_states = `&marketplace_shipping_state=${marketplace_shipping_state}`
  }
  if (shipping_address_id_params === null) {
    shipping_address_id = '';
  } else {
    shipping_address_id = `&initial_destination_id=${shipping_address_id_params}`
  }

  if (sort === null) {
    onSort = '&sort=sorted';
  } else {
    onSort = ``
  }
  if (sort === null) {
    sortParams = '';
  } else {
    sortParams = `&sort=${sort}`;
  }

  const dataInfo = useGetAllOrdersQuery({ page: page, limit: limit, states: states, marketplace_shipping_states: marketplace_shipping_states, initial_destination_id: shipping_address_id, order_number: order_numbers }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const dataList = dataInfo?.data?.order_items
  const pagination = dataInfo?.data?.meta?.pagination

  const dataInfoNav = useGetAllOrdersNavQuery({ page: page, limit: limit, states: states, marketplace_shipping_states: marketplace_shipping_states }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const dataListNav = dataInfoNav?.data?.order_items

  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9]/g;

  function sortAlphaNum(a, b) {
    const aA = a.merchant_location?.replace(reA, "");
    const bA = b.merchant_location?.replace(reA, "");
    if (aA === bA) {
      const aN = parseInt(a.merchant_location?.replace(reN, ""), 10);
      const bN = parseInt(b.merchant_location?.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }

  const dataArrayList = sort ? dataList?.slice().sort(sortAlphaNum) : dataList;


  const [AcceptOrder, updateresult] = useAcceptOrderMutation()
  const [RejectOrder, rejectresult] = useRejectOrderMutation()

  const config = {
    background: "#f5f5f5",
    marginTop: "20px",
    marginBottom: "20px",
    height: 60
  };

  const [show, setShow] = useState(false);
  const [value, setValue] = useState(null);

  function handleClose() {
    setShow(false)
  }
  function handleOpen(data) {
    setShow(data.status)
    setValue(data.itemId)
  }

  let printRef = null;

  const [getDeatsilsShow, setDeatsilsShow] = useState(false);
  const [getViewData, setViewData] = useState("");
  function detailsClose() {
    setDeatsilsShow(false)
  }
  function displayProductDetails(data) {
    setDeatsilsShow('true')
    setViewData(data)
  }

  const [getStoreUrl, setStoreUrl] = useState("");
  const [getAttachFile, setAttachFile] = useState("");
  const [inputsA, setInputsA] = useState([]);
  const [inputsR, setInputsR] = useState([]);
  const splitValue = inputsA.toString().split(',').join('-')
  const splitValueR = inputsR.toString().split(',').join('-')
  const [getUploadFileModal, setUploadFileModal] = useState(false);
  const [getSendRequestModal, setSendRequestModal] = useState(false);

  function uploadFile() {
    if (inputsA.length > 0) {
      setUploadFileModal(true)
    } else {
      alert('Please select the order items you want to attach invoices for.')
    }
  }
  function uploadFileClose() {
    setUploadFileModal(false)
  }

  function sendRequest() {
    if (inputsR.length > 0) {
      setSendRequestModal(true)
    } else {
      alert('Please select the order items you want to request payments for.')
    }
  }
  function sendRequestModalClose() {
    setSendRequestModal(false)
  }

  const [showElement, setShowElement] = React.useState(true)
  useEffect(() => {
    setTimeout(function () {
      setShowElement(false)
    }, 3000);
  },
    [props.getHttpStatusR, props.HttpStatussCode, props.getStatus, props.StoreCreateMsg])


  const acceptedOrders = (state === 'accepted' && marketplace_shipping_state === 'pending_shipment' && true)
  const deliveredOrders = (state === 'accepted' && marketplace_shipping_state === 'delivered' && true)
  const allOrders = (state === null && marketplace_shipping_state === null && true)
  const inTransitOrders = (state === 'accepted' && marketplace_shipping_state === 'in_transit' && true)
  const pendingOrders = (state === 'pending' && marketplace_shipping_state === null && true)
  const rejectedOrders = (state === 'rejected' && marketplace_shipping_state === null && true)


  const [getAcceptModal, setAcceptModal] = useState(false);
  const [getReferenceNumber, setReferenceNumber] = useState('');
  function AcceptModal(data) {
    setAcceptModal(true)
  }

  function closeAcceptModal() {
    setAcceptModal(false)
  }


  let uniquIds = [];
  let newArray = dataListNav && dataListNav?.filter((item) => {
    if (!uniquIds.includes(item.shipping_address.shipping_address_id)) {
      uniquIds.push(item.shipping_address.shipping_address_id);
      return true;
    }
    return false;
  });


  // accept multiple orders
  const [getAcceptCheckbox, setAcceptCheckbox] = useState([]);
  function selectMultiple(data) {
    const resultaaaa = getAcceptCheckbox?.filter(itemsss => itemsss === data);
    if (resultaaaa?.length > 0) {
      return true
    } else {
      return false
    }
  }

  function selectAllOrder() {
    if (dataList?.length > 0) {
      if (getAcceptCheckbox?.length === dataList?.length) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
  const [getCounter, setCounter] = useState(0);
  function acceptSelectedOrder(data) {
    let counter = 0;
    data?.item_ids?.forEach(async (item) => {
      try {
        await AcceptOrder({ "order_item_id": item, "seller_reference_number": data.reference_number, "format": "json" })
        // setAcceptCheckbox([]);
      } catch (error) {
      }
      setCounter(counter++)


    })
  }

  useEffect(() => {
    if (getAcceptCheckbox?.length === getCounter + 1) {
      setAcceptModal(false);
      setAcceptCheckbox([]);
      if (getAcceptCheckbox?.length > 1) {
        window.open(`${window.location.pathname}?state=pending&page=${page}&size=${limit}`, "_self")
      } else {
        return <Redirect to={`${window.location.pathname}?state=pending&page=${page}&size=${limit}&update=update`} />
      }
    }
  }, [getCounter, dataList, updateresult])



  // create multiple shipment
  const [getMovementCheckbox, setMovementCheckbox] = useState([]);
  function selectMultipleMovement(data) {
    const resultaaaa = getMovementCheckbox?.filter(itemsss => itemsss === data);
    if (resultaaaa?.length > 0) {
      return true
    } else {
      return false
    }
  }

  function selectAllMovement() {
    if (dataList?.length > 0) {
      if (getMovementCheckbox?.length === dataList?.length) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  function createShipment() {
    if (shipping_address_id_params) {
      history.push(`/multiple-shipment?initial_destination_id=${shipping_address_id_params}&order_ids=${getMovementCheckbox}`)
    } else {
      alert("Kindly select the shipment location!")
    }

  }

  const [StoreCreateMsg, setSearchQuery] = useState("");
  function onSubmit(data, e) {
    history.push(`/orders?order_id=${data.searchQuery}&page=1&size=20${states}${marketplace_shipping_states}${shipping_address_id}`);
    e.target.reset();
    setSearchQuery("");
  }


  function getWarehouseLocation(locationId, city) {
    if (locationId === 28) {
      return 'DE Consolidation'
    }
    else if (locationId === 27) {
      return 'DE Distribution'
    }
    else if (!city) {
      return 'Location'
    } else {
      return city
    }
  }


  return (
    <div className="orderStyle">
      <Modal className="mdlPopup product_detailsModal" show={getDeatsilsShow} onHide={detailsClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Order Details #{getViewData?.Order_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="det_Sty"><strong>Order Number:&nbsp;&nbsp; </strong>{getViewData?.order_number}</div>
            <div className="det_Sty"><strong>Order Item ID:&nbsp;&nbsp; </strong>{getViewData?.Order_id}</div>
            <div className="det_Sty"><strong>Product ID:&nbsp;&nbsp; </strong>{getViewData?.product_id}</div>
            <div className="det_Sty"><strong>Acceptance Expiration:&nbsp;&nbsp; </strong>
              {moment(getViewData?.acceptance_expiration_at * 1000).format('MMMM Do YYYY')}</div>
            <div className="det_Sty"><strong>Accepted at:&nbsp;&nbsp; </strong>
              {moment(getViewData?.accepted_at * 1000).format('MMMM Do YYYY')}</div>
            <div className="det_Sty"><strong>Currency:&nbsp;&nbsp; </strong>{getViewData?.currency_code}</div>
            <div className="det_Sty"><strong>Estimated Delivery Days:&nbsp;&nbsp; </strong>{getViewData?.estimated_delivery_days}</div>
            <div className="det_Sty"><strong>Order Place at:&nbsp;&nbsp; </strong>
              {moment(getViewData?.order_placed_at * 1000).format('MMMM Do YYYY')}</div>
            <div className="det_Sty">
              <span className="fsize12 mbSzAdd"><strong>Shipping to:</strong>&nbsp;{getViewData?.shipping_address_name},&nbsp;{getViewData?.shipping_address1},{getViewData?.shipping_address2},&nbsp;{getViewData?.shipping_address_city},&nbsp;{getViewData?.shipping_address_country}</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="mdlPopup barcode_Modal" show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="merrMsg">
            <div>
              <div className="merrMsgm">Please print this <strong>Barcode</strong> and attach it on the item</div>
            </div>
          </div>
          <div className="row barWiR">
            <div className="barWi" ref={el => (printRef = el)} >
              <div className="col-md-12 adBarcodeW">
                <div className="form-group alignBar">
                  <div className="disTextB">
                  </div>
                  <Barcode value={value} {...config} />
                </div>
              </div>
            </div>
          </div>
          <div className="row printRow">
            <div className="col-md-12">
              <div className="form-group printFlexb">
                <ReactToPrint
                  trigger={() => <Button variant="info" size="sm" className="hghtbtn">Print</Button>}
                  content={() => printRef}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="mdlPopup invoice_Modal" show={props.getStatus ? false : getUploadFileModal} onHide={uploadFileClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input type="file" className="form-control fileUpload dlrdflUpload" id="attachFile" onChange={(e) => setAttachFile(e.target.files)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input type="text" className="form-control dlrdflInput" placeholder="Order ID's" readOnly id="splitValue" value={inputsA} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Button className="hghtbtn" variant="info" onClick={() => props.uploadFileAttach({ ItemIds: inputsA, attachFile: getAttachFile })}>Upload</Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="mdlPopup payment_Modal" show={getSendRequestModal} onHide={sendRequestModalClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Request For Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="payment-request-message">
            <span><strong>For any payment support <br /> kindly contact us at:</strong></span>
            <div>
              <div className="suppotCard">
                <span className="supportText">merchant@desertcart.ae</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <Modal className="mdlPopup invoice_Modal" show={getAcceptModal} onHide={closeAcceptModal} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reference Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <label>Enter your reference number</label>
              <div className="form-group">
                <input
                  style={{ border: '1px solid #eeee' }}
                  type="text"
                  className="form-control fileUpload dlrdflUpload"
                  value={getReferenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                {updateresult.isLoading || (updateresult.isFetching && !updateresult.isLoading) ?
                  <Button className="hghtbtn" variant="info" disabled style={{ width: '100%' }}>Please wait... Accepting - {getCounter}</Button> :
                  <Button className="hghtbtn" variant="info" style={{ width: '100%' }}
                    onClick={() => acceptSelectedOrder({ "item_ids": getAcceptCheckbox, "reference_number": getReferenceNumber })}
                  >Accept</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>


      <div className="container">
        <div className="row cardList__margin">
          <div className="col-md-12">
            <div className="Table-header">
              <Navigation dataInfo={dataInfo} />

              <div className="dFlex-sb">
                <div className="dFlex">
                  {acceptedOrders &&
                    <>
                      {getMovementCheckbox.length > 0 ?
                        <Button size="sm" variant="success" className="mr10" onClick={createShipment} >Create Shipment - {getMovementCheckbox?.length}</Button>
                        : <Button size="sm" variant="success" disabled className="mr10">Create Shipment</Button>}
                    </>
                  }
                  {pendingOrders &&
                    <>
                      {getAcceptCheckbox.length > 0 ?
                        <Button size="sm" variant="warning" className="mr10"
                          onClick={() => AcceptModal()}
                        >Accept - {getAcceptCheckbox?.length > 0 && getAcceptCheckbox?.length}</Button>
                        : <Button size="sm" variant="success" disabled className="mr10">Accept</Button>}
                    </>
                  }
                  {deliveredOrders &&
                    <>
                      {inputsA.length > 0 ? <Button size="sm" variant="info" className="mr10" onClick={uploadFile}>Attach invoices</Button> :
                        <Button size="sm" variant="info" disabled className="mr10"  >Attach invoices</Button>}
                      {inputsR.length > 0 ? <Button size="sm" variant="primary" onClick={sendRequest} className="mr10">Payment Request</Button> :
                        <Button size="sm" variant="primary" className="mr10" disabled>Payment Request</Button>}
                    </>}

                  {allOrders &&
                    <div className="hdMob">
                      {pagination?.total_count > 0 &&
                        <>
                          {props.store_id != 2205 &&
                            <>
                              {props.getOrderExLoa ?
                                <Button size="sm" variant="warning" disabled className="mr10">
                                  <span className="hdMob ">Please wait...</span>
                                </Button>
                                :
                                <Button size="sm" variant="warning" className="mr10" onClick={props.ordersDownload}>
                                  <span className="hdMob">Export List</span>
                                </Button>
                              }
                            </>}
                        </>}
                    </div>}
                  <div className="mr10 hdMob">
                    <Button size="sm" variant="outline-secondary" disabled>List.:&nbsp;{pagination?.total_count}</Button>
                  </div>
                  <div className="mr10">
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic">
                        Size &nbsp;{props.pageSizeb}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ "minWidth": "20px" }}>
                        <Dropdown.Item as={Link} to={{ pathname: props.pathName, search: `?size=10&page=1${order_numbers}${states}${marketplace_shipping_states}${shipping_address_id}${sortParams}` }}>10</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: props.pathName, search: `?size=20&page=1${order_numbers}${states}${marketplace_shipping_states}${shipping_address_id}${sortParams}` }}>20</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: props.pathName, search: `?size=30&page=1${order_numbers}${states}${marketplace_shipping_states}${shipping_address_id}${sortParams}` }}>30</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: props.pathName, search: `?size=40&page=1${order_numbers}${states}${marketplace_shipping_states}${shipping_address_id}${sortParams}` }}>40</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: props.pathName, search: `?size=50&page=1${order_numbers}${states}${marketplace_shipping_states}${shipping_address_id}${sortParams}` }}>50</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: props.pathName, search: `?size=60&page=1${order_numbers}${states}${marketplace_shipping_states}${shipping_address_id}${sortParams}` }}>60</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: props.pathName, search: `?size=70&page=1${order_numbers}${states}${marketplace_shipping_states}${shipping_address_id}${sortParams}` }}>70</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: props.pathName, search: `?size=80&page=1${order_numbers}${states}${marketplace_shipping_states}${shipping_address_id}${sortParams}` }}>80</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: props.pathName, search: `?size=90&page=1${order_numbers}${states}${marketplace_shipping_states}${shipping_address_id}${sortParams}` }}>90</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: props.pathName, search: `?size=100&page=1${order_numbers}${states}${marketplace_shipping_states}${shipping_address_id}${sortParams}` }}>100</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {props.store_id === 2 &&
                    <form onSubmit={handleSubmit(onSubmit)} className="hideOnMob">
                      <div className="searchHeader">
                        <input
                          type="text"
                          placeholder="Search by order number..."
                          className="form-control srchrds100"
                          onChange={(e) => setSearchQuery(e.target.value)}
                          id="searchQuery"
                          aria-invalid={errors.firstName ? "true" : "false"}
                          {...register("searchQuery", { required: true })}
                        />
                        <Button variant="info" className="searchIcon" type="submit">
                          <SearchIcon className="search__Icon" />
                        </Button>
                      </div>
                    </form>
                  }
                </div>
                <div className="newFlex overflow-horizontal">
                  <Pagination pagination={pagination} page={page} limit={limit} states={states} order_numbers={order_numbers} marketplace_shipping_states={marketplace_shipping_states} shipping_address_id={shipping_address_id} sortParams={sortParams} />
                </div>
              </div>
              {(acceptedOrders || pendingOrders) &&
                <>
                  <div className="cardList__overflow_2">
                    <h6 className="Order-Select__Shjippment">Shipment Location {'> '}&nbsp;</h6>
                    {dataInfoNav?.isLoading &&
                      <div className="col-md-2 box_ppdng mobSbox">
                        <Skeleton variant="rect" style={{ padding: '10px', height: '40px' }} />
                      </div>
                    }
                    {!dataInfoNav?.isLoading &&
                      <>
                        {newArray?.map((item, i) => (
                          <div className="col-md-2 box_ppdng mobSbox">
                            <Link to={{ pathname: props.pathName, search: `?size=${limit}&page=${page}&initial_destination_id=${item.shipping_address?.shipping_address_id}${states}${marketplace_shipping_states}` }}>
                              <div className={shipping_address_id_params == item.shipping_address?.shipping_address_id ? 'boxcardW_2 alert alert-secondary' : 'boxcard_2'}>
                                <div className="heading">
                                  <div className="pendingOrdersCards">
                                    {getWarehouseLocation(item.shipping_address?.shipping_address_id, item.shipping_address?.city)}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                      </>
                    }
                  </div>
                </>
              }
            </div>
            <div className="orders_tbaleView">
              <table className="table">
                <thead>
                  <tr>
                    {acceptedOrders &&
                      <th style={{ cursor: 'pointer' }} className={`${selectAllMovement() && 'alert alert-info'}`} for={`selectButtonAll`}
                        onClick={() => {
                          if (shipping_address_id_params) {
                            const indexxx = dataList?.map((item) => item.id)
                            if ((getMovementCheckbox?.length > 0 && getMovementCheckbox?.length !== dataList?.length) || getMovementCheckbox?.length === 0) {
                              setMovementCheckbox(indexxx)
                            } else {
                              setMovementCheckbox([])
                            }
                          } else { alert("Kindly select the shipment location!") }
                        }}>
                        {getMovementCheckbox?.length === dataList?.length && getMovementCheckbox?.length != 0 ? 'Deselect All' : 'Select All'}
                        <input type="checkbox" className="chckSelect" value={getMovementCheckbox}
                          style={{ display: 'none' }}
                          checked={selectAllMovement()} />
                      </th>
                    }
                    {pendingOrders &&
                      <th style={{ cursor: 'pointer' }} className={`${selectAllOrder() && 'alert alert-info'}`} for={`selectButtonAll`}
                        onClick={() => {
                          if (shipping_address_id_params) {
                            const indexxx = dataList?.map((item) => item.id)
                            if ((getAcceptCheckbox?.length > 0 && getAcceptCheckbox?.length !== dataList?.length) || getAcceptCheckbox?.length === 0) {
                              setAcceptCheckbox(indexxx)
                            } else {
                              setAcceptCheckbox([])
                            }
                          } else { alert("Kindly select the shipment location!") }
                        }}>
                        {getAcceptCheckbox?.length === dataList?.length && getAcceptCheckbox?.length != 0 ? 'Deselect All' : 'Select All'}
                        <input type="checkbox" className="chckSelect" value={getAcceptCheckbox}
                          style={{ display: 'none' }}
                          checked={selectAllOrder()} />
                      </th>
                    }
                    {deliveredOrders && <th><SelectAllIcon /></th>}
                    {deliveredOrders && <th>Invoice</th>}
                    <th>SKU/UID</th>
                    <th>
                      <div className="Order-page-sort">
                        <span className="number">Aisle No.</span>
                        <Link
                          to={{ pathname: `${window.location.pathname}`, search: `?size=${limit}&page=${page}${order_numbers}${states}${marketplace_shipping_states}${shipping_address_id}${onSort}` }}><span><SortIcon style={{ color: sort ? '#000' : '#d0d0d0', fontSize: '1.3rem' }} /></span></Link>
                      </div>
                    </th>
                    <th className="imgCenter">Images</th>

                    {deliveredOrders && <th>Payout</th>}
                    {inTransitOrders && <th>Barcode</th>}
                    <th>Price</th>
                    <th>Title</th>
                    <th>Order Placed At</th>
                    <th className="sticky-column">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {dataInfo.isLoading &&
                    <TableSkeleten />
                  }
                  {/* {dataInfo.isFetching && !dataInfo.isLoading &&
                    <TableSkeleten />
                  } */}
                  {!dataInfo.isLoading &&
                    <>
                      {dataArrayList?.map((item, i) =>
                        <tr key={i}>
                          {deliveredOrders &&
                            <td className="table_sizeTD_Manage">
                              {item.payout_status === 'pending' &&
                                <div className="checkBox_DF">
                                  <span className="checkCenter">
                                    {item.invoice_url === null ?
                                      <label className="selectButton reFP checkBox_D" for={`selectButton${item.id}`}>
                                        <input id={`selectButton${item.id}`} type="checkbox" className="chckSelect" value={item.id}
                                          onClick={() => {
                                            const index = inputsA.findIndex(itema => itema === item.id)
                                            if (~index) { setInputsA([...inputsA.slice(0, index), ...inputsA.slice(index + 1)]) }
                                            else { setInputsA([...inputsA, item.id]) }
                                          }} />
                                      </label>
                                      :
                                      <span className="checkCenter">
                                        <label className="selectButton reFP checkBox_D" for={`selectButton${item.id}`}>
                                          <input id={`selectButton${item.id}`} type="checkbox" className="chckSelect" value={item.id}
                                            onClick={() => {
                                              const index = inputsR.findIndex(itemb => itemb === item.id)
                                              if (~index) { setInputsR([...inputsR.slice(0, index), ...inputsR.slice(index + 1)]) }
                                              else { setInputsR([...inputsR, item.id]) }
                                            }} />
                                        </label>
                                      </span>
                                    }
                                  </span>
                                </div>
                              }
                            </td>
                          }
                          {acceptedOrders &&
                            <td className="table_sizeTD_Manage">
                              <div className="checkCenter">
                                <label className="selectButton checkBox_D" for={`selectButton${item.id}`}>
                                  <input id={`selectButton${item.id}`} type="checkbox" className="chckSelect" value={item.id}
                                    checked={selectMultipleMovement(item.id)}
                                    onClick={() => {
                                      if (shipping_address_id_params) {
                                        const index = getMovementCheckbox.findIndex(itema => itema === item.id)
                                        if (~index) { setMovementCheckbox([...getMovementCheckbox.slice(0, index), ...getMovementCheckbox.slice(index + 1),]) }
                                        else { setMovementCheckbox([...getMovementCheckbox, item.id,]) }
                                      } else {
                                        alert("Kindly select the shipment location!")
                                      }
                                    }} />
                                </label>
                              </div>
                            </td>
                          }

                          {pendingOrders &&
                            <td className="table_sizeTD_Manage">
                              <div className="checkCenter">
                                <label className="selectButton checkBox_D" for={`selectButton${item.id}`}>
                                  <input id={`selectButton${item.id}`} type="checkbox" className="chckSelect" value={item.id}
                                    checked={selectMultiple(item.id)}
                                    onClick={() => {
                                      if (shipping_address_id_params) {
                                        const index = getAcceptCheckbox.findIndex(itema => itema === item.id)
                                        if (~index) { setAcceptCheckbox([...getAcceptCheckbox.slice(0, index), ...getAcceptCheckbox.slice(index + 1),]) }
                                        else { setAcceptCheckbox([...getAcceptCheckbox, item.id,]) }
                                      } else {
                                        alert("Kindly select the shipment location!")
                                      }
                                    }} />
                                </label>
                              </div>
                            </td>
                          }


                          {deliveredOrders && <td className="table_sizeTD_Manage">
                            {item.invoice_url === null ?
                              <small className="selectButtonS">
                                <ErrorIcon className="invCheckE" />
                              </small>
                              :
                              <small className="selectButtonS">
                                <CheckBoxIcon className="invCheck" />
                              </small>}
                          </td>}

                          <td className="table_sizeTD_Manage ProView_details" style={{ textAlign: 'center' }}>
                            {props.store_id === 2 ?
                              <a href={`https://business.walmart.com/ip/seort/${item.store_uid}`} target="_blank">{item.store_uid}</a>
                              :
                              item.store_uid
                            }
                          </td>
                          <td className="table_sizeTD_Manage ProView_details" style={{ textAlign: 'center' }}>
                            {item.merchant_location && item.merchant_location}
                          </td>
                          <td className="table_sizeTD">
                            <div className="cardImage">
                              <img className="cardImages" src={item.image_urls[0] ? item.image_urls[0] : '/image-not-found.png'} width="120px" />
                            </div>
                          </td>

                          {deliveredOrders &&
                            <td className="table_sizeTD_Manage">
                              <span>
                                <div size="sm" className="manageButtons manageButtonsmrgn">
                                  {item.payout_status === 'pending' ? <Button variant="warning" size="sm" className="manageButtons" disabled><small>Pending</small></Button> : null}
                                  {item.payout_status === 'paid' ? <Button variant="success" size="sm" className="manageButtons" disabled><small>paid</small></Button> : null}
                                  {item.payout_status === 'approved' ? <Button variant="info" size="sm" className="manageButtons" disabled><small>Approved</small></Button> : null}
                                </div></span>
                            </td>}
                          {inTransitOrders &&
                            <td className="table_sizeTD_Manage">
                              <span>
                                <Button size="sm" variant="warning" className="manageButtons" onClick={() => handleOpen({ "status": true, "itemId": `M-${item.movements[0].id}`, "title": item.title })}>
                                  <small>Barcode</small>
                                </Button>
                              </span>
                            </td>}
                          <td className="table_sizeTD table_sizeTD_Manage">
                            {item.currency_code}&nbsp; {item.price / 100}
                          </td>
                          <td>
                            <div className="product_TitleWrap ProView_details">
                              {item.title}
                            </div>
                          </td>
                          <td>{moment.utc(item.order_placed_at * 1000).local().format('MMMM Do YYYY, h:mm')}</td>

                          <td className="table_sizeTD_Manage sticky-column">
                            <span>
                              <Button size="sm" variant="info" className="status-buttons button-margin" onClick={() => displayProductDetails({
                                "Order_id": item.id,
                                "acceptance_expiration_at": new Date(item.acceptance_expiration_at * 1000).toISOString().slice(0, 19).replace('T', ' '),
                                "accepted_at": new Date(item.accepted_at * 1000).toISOString().slice(0, 19).replace('T', ' '),
                                "estimated_delivery_days": item.estimated_delivery_days,
                                "currency_code": item.currency_code,
                                "estimated_delivery_date": item.estimated_delivery_date,
                                "order_placed_at": item.order_placed_at,
                                "product_id": item.product_id,
                                "order_number": item.order_reference,
                                "shipping_address_name": item.shipping_address.name,
                                "shipping_address1": item.shipping_address.address_line_1,
                                "shipping_address2": item.shipping_address.address_line_2,
                                "shipping_address_city": item.shipping_address.city,
                                "shipping_address_country": item.shipping_address.country
                              })}>
                                <small>Details</small>
                              </Button>

                              {allOrders && (
                                <>
                                  {item.state === "rejected" && (
                                    <Button size="sm" variant="danger" className="status-buttons" disabled >
                                      <small>Rejected</small>
                                    </Button>
                                  )}
                                  {item.state === "pending" && (
                                    <Button size="sm" variant="warning" className="status-buttons button-margin" disabled >
                                      <small>Pending</small>
                                    </Button>
                                  )}
                                  {item.state === "accepted" && item.marketplace_shipping_state === "in_transit" && (
                                    <Button size="sm" variant="info" className="status-buttons" disabled >
                                      <small>In Transit</small>
                                    </Button>)}
                                  {item.state === "accepted" && item.marketplace_shipping_state === "delivered" && (
                                    <Button size="sm" variant="success" className="status-buttons" disabled >
                                      <small>Delivered</small>
                                    </Button>)}
                                  {item.state === "accepted" && item.marketplace_shipping_state === "pending_shipment" && (
                                    <>
                                      <Button size="sm" variant="success" className="status-buttons button-margin" disabled >
                                        <small>Accepted</small>
                                      </Button>
                                    </>
                                  )}
                                </>
                              )}

                              {item.state === "pending" && (
                                <>
                                  {rejectresult?.isLoading && rejectresult?.originalArgs?.order_item_id === item.id ?
                                    <Button size="sm" variant="danger" className="status-buttons" disabled >
                                      <small>Loading</small>
                                    </Button>
                                    :
                                    <Button size="sm" variant="danger" className="status-buttons" onClick={() => RejectOrder({ "order_item_id": item.id, "reason": "seller_out_of_stock", "format": "json" })} >
                                      <small>Reject</small>
                                    </Button>
                                  }
                                </>
                              )}
                              {item.state === "accepted" && item.marketplace_shipping_state === "pending_shipment" && (
                                <>
                                  {rejectresult?.isLoading && rejectresult?.originalArgs?.order_item_id === item.id ?
                                    <Button size="sm" variant="danger" className="status-buttons" disabled >
                                      <small>Loading</small>
                                    </Button>
                                    :
                                    <Button size="sm" variant="danger" className="status-buttons" onClick={() => RejectOrder({ "order_item_id": item.id, "reason": "seller_out_of_stock", "format": "json" })} >
                                      <small>Reject</small>
                                    </Button>
                                  }
                                </>
                              )}
                            </span>
                          </td>
                        </tr>
                      )}
                    </>
                  }
                </tbody>
              </table>
              {dataInfo.isSuccess && dataInfo.status !== 'pending' &&
                <>
                  {pagination?.total_count === 0 &&
                    <div className="row">
                      <div className="col-md-12">
                        <div className="storeCard emptyCard">
                          <div>
                            <h5 className="listHeadng emptyCard_Text">No data found!
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>}
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PendingOrders;
