import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import { Button, Table, Spinner, Modal } from 'react-bootstrap'
import Barcode from "react-hooks-barcode";
import ReactToPrint from "react-to-print";
import ReactTOPdf from "react-to-pdf";
const ref = React.createRef();

function CreateShipment() {
  const location = useLocation();
  const history = useHistory();
  const user_token = JSON.parse(localStorage.getItem("user_token"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));

  const [carrier, setCarrier] = useState("");
  const [tracking_number, setTrackingNumber] = useState("");
  const [order_item_ids, setOrderItems] = useState("");
  const [shipping_address_id, setShipmentId] = useState("");
  const order_item_idsab = order_item_ids.toString().split(',');
  const [isLoading, setLoading] = useState(false)
  const [httpCodeStatus, setHttpStatus] = useState("")



  const [inputs, setInputs] = useState([]);
  function uploadFile() {
    history.push(`/multiple-shipment?order_id=${inputs}`)
  }
  const config = {
    background: "#f5f5f5",
    marginTop: "20px",
    marginBottom: "20px",
    height: 60
  };
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(null);
  const [getTracking, setTracking] = useState("");
  const [getError, setError] = useState("");
  const [message, setMessage] = useState(null);
  useEffect(
    () => {
      if (!value) {
        setMessage("Please select the order");
      } else setMessage(null);
    },
    [value]
  );
  function handleClose() {
    setShow(false)
  }
  // function handleOpen(data) {
  //   setShow(data.status)
  //   setValue(data.itemId)
  //   setTitle(data.title)
  // }
  let printRef = null;

  const address_line_1 = new URLSearchParams(location.search).get('address_line_1')
  const address_line_2 = new URLSearchParams(location.search).get('address_line_2')
  const city = new URLSearchParams(location.search).get('city')
  const country = new URLSearchParams(location.search).get('country')
  const name = new URLSearchParams(location.search).get('name')

  useEffect(() => {
    setOrderItems(new URLSearchParams(location.search).get('order_id'))
    setShipmentId(new URLSearchParams(location.search).get('shipment_id'))
  }, []);

  var departedAt = Math.round(+new Date() / 1000);
  function createShipment() {

    let storeData = {
      carrier: carrier,
      tracking_number: tracking_number,
      shipping_address_id: shipping_address_id,
      order_item_ids: order_item_idsab,
      departed_at: departedAt,
    };
    setLoading(true);
    fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/movements`, {
      method: "POST",
      headers: {
        'Accept': 'application/vnd.api+json; version:3.0',
        'X-User-Id': user_id,
        'X-User-Token': user_token,
        'X-Store-Id': store_id,
        'X-Store-Token': store_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(storeData)
    }).then((result) => {
      result.json().then((resp) => {
        setHttpStatus(result.status);
        if (result.status === 201) {
          setShow('true')
          setValue(resp.movement ? `M-${resp.movement.id}` : null)
          setTracking(resp.movement ? resp.movement.tracking_number : null)
          setLoading(false)
        }
        setLoading(false)

      })
    })
  }
  return (
    <div className="shipmentStyle">
      <Modal className="mdlPopup" show={show} onHide={handleClose} backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title> </Modal.Title>
          <div><small>#{getTracking}</small></div>
        </Modal.Header>
        {isLoading ? <div className="dcLoader1">&nbsp;</div> : null}
        <Modal.Body>
          <div className="merrMsg">
            <div>
              <div className="merrMsgm">Shipment has been created successfully!<br />Please print this <strong>Barcode</strong> and attach it on the item.</div>
            </div>
          </div>
          <div className="row barWiR" ref={ref}>
            <div className="barWi" ref={el => (printRef = el)}>
              {/* <div className="col-md-12 adBarcodeW">
                <div className="form-group alignBar" >
                  <div className="barHdr">
                    <img
                      className="headerLogo"
                      src="https://cdn.desertcart.com/static/media/logo.5821c90d.png"
                      width="40px"
                    ></img>
                    <div>
                      <label className="barHdrname"> Inbound barcode</label>
                    </div>
                  </div>
                  <div className="disTextB">
                    <p><strong>{value}</strong></p>
                  </div>
                  <Barcode value={value} {...config} />
                </div>
              </div>  */}
              {/* {order_item_ids.split(',').map((step) =>
                <div className="col-md-12 adBarcodeW">
                  <div className="form-group alignBar">
                    <div className="disTextB">
                    </div>
                    <Barcode value={(`I-${step}`)} {...config} />
                  </div>
                </div>
              )} */}
              <div className="col-md-12 adBarcodeW">
                <div className="form-group alignBar">
                  <div className="disTextB">
                  </div>
                  <Barcode value={value} {...config} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 barBotmR">
              <div className="form-group printFlexb">
                <ReactToPrint
                  trigger={() =>
                    <Button variant="info" size="sm" className="hghtbtn printButton">Print</Button>}
                  content={() => printRef}
                />
                {/* <ReactTOPdf targetRef={ref} >
                  {({ toPdf }) =>
                    <Button variant="warning" size="sm" onClick={toPdf} className="getDownloadB">
                      Download
                    </Button>
                  }
                </ReactTOPdf> */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="container">
        <div className="row newStore">
          <div className="col-md-6">
            <div className="storeCard BrdrRound">

              <h4>Shipping Address</h4>
              <div className="displayShip">
                <span>
                  <strong>Address:&nbsp; </strong>
                </span>
                <span>
                  {address_line_1}, {address_line_2}
                </span>
              </div>
              <div className="displayShip">
                <span>
                  <strong>City:&nbsp; </strong>
                </span>
                <span>{city}</span>
              </div>
              <div className="displayShip">
                <span>
                  <strong>Country:&nbsp; </strong>
                </span>
                <span>{country}</span>
              </div>
              <div className="displayShip">
                <span>
                  <strong>Name:&nbsp; </strong>
                </span>
                <span>{name}</span>
              </div>
              <div className="displayShip">
                <span>
                  <strong>Shipping ID:&nbsp; </strong>
                </span>
                <span>{shipping_address_id}</span>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="storeCardH BrdrRound">
              {isLoading ? <div className="dcLoader1">&nbsp;</div> : <div style={{ "border": "3px solid #fff" }}></div>}
              <div className="storeCardB">
                {httpCodeStatus === 201 ? (
                  <div>
                    <div>
                      <h5 className="alert alert-success">
                        Shipment has been created successfully!
                      </h5>
                    </div>
                  </div>
                ) : null}
                {httpCodeStatus === 500 ? (
                  <div>
                    <div>
                      <h5 className="alert alert-danger">Sorry, something went wrong</h5>
                    </div>
                  </div>
                ) : null}
                {httpCodeStatus === 422 ? (
                  <div>
                    <div>
                      <h5 className="alert alert-danger">
                        Items in movement should have same shipping address (can't
                        be blank), (already shipped), (already exists)
                      </h5>
                    </div>
                  </div>
                ) : null}
                {httpCodeStatus === 400 ? (
                  <div>
                    <div>
                      <h5 className="alert alert-danger">Bad Request</h5>
                    </div>
                  </div>
                ) : null}

                <div className="storeCardtextNew">
                  <h5 className="listHeadngNew">Create Shipment</h5>
                </div>
                <div className="form-group">
                  <label>Carrier</label>
                  <input
                    tyep="text"
                    name="carrier"
                    className="form-control styleInput"
                    onChange={(e) => setCarrier(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Shipping ID</label>
                  <input
                    tyep="text"
                    readOnly
                    name="shipping_address_id"
                    className="form-control styleInput"
                    defaultValue={shipping_address_id}
                    onChange={(e) => setShipmentId(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Tracking Number</label>
                  <input
                    tyep="text"
                    name="tracking_number"
                    className="form-control styleInput"
                    onChange={(e) => setTrackingNumber(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label>Order Item IDs i.e (12312,123123)</label>
                  <textarea
                    tyep="text"
                    name="order_item_ids"
                    className="form-control styleTextarea"
                    defaultValue={order_item_ids}
                    onChange={(e) => setOrderItems(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <Button
                    variant="info"
                    className="Button"
                    onClick={createShipment}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateShipment;
