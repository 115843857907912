import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_DC_API}/api/`,
  }),

  tagTypes: ['Products'],
  // keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: (data) => {
        return {
          url: `marketplace/products${data.querya}?${data.queryb}&page[number]=${data.page}&page[size]=${data.limit}&blacklisted=${data.blacklisted}`,
          method: 'GET',
          headers: {
            'Accept': 'application/vnd.api+json; version:3.0',
            'X-User-Id': JSON.parse(localStorage.getItem('user_id')),
            'X-User-Token': JSON.parse(localStorage.getItem('user_token')),
            'X-Store-Id': JSON.parse(localStorage.getItem("store_id")),
            'X-Store-Token': JSON.parse(localStorage.getItem("store_token")),
            'Content-Type': 'application/json'
          }
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Products'],
    }),


    getAllProductPrice: builder.query({
      query: (data) => {
        return {
          url: `marketplace/products/${data.ItemId}`,
          method: 'GET',
          headers: {
            'Accept': 'application/vnd.api+json; version:3.0',
            'X-User-Id': JSON.parse(localStorage.getItem('user_id')),
            'X-User-Token': JSON.parse(localStorage.getItem('user_token')),
            'X-Store-Id': JSON.parse(localStorage.getItem("store_id")),
            'X-Store-Token': JSON.parse(localStorage.getItem("store_token")),
            'Content-Type': 'application/json'
          }
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Products'],
    }),


    deleteProduct: builder.mutation({
      query: (data) => {
        return {
          url: `marketplace/products/${data.ItemId}`,
          method: 'DELETE',
          body: data,
          headers: {
            'Accept': 'application/vnd.api+json; version:3.0',
            'X-User-Id': JSON.parse(localStorage.getItem('user_id')),
            'X-User-Token': JSON.parse(localStorage.getItem('user_token')),
            'X-Store-Id': JSON.parse(localStorage.getItem("store_id")),
            'X-Store-Token': JSON.parse(localStorage.getItem("store_token")),
            'Content-Type': 'application/json'
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Products'],
    }),


    updateProduct: builder.mutation({
      query: (data) => {
        return {
          url: `marketplace/store_product_lists`,
          method: 'POST',
          body: data,
          headers: {
            'Accept': 'application/vnd.api+json; version:3.0',
            'X-User-Id': JSON.parse(localStorage.getItem('user_id')),
            'X-User-Token': JSON.parse(localStorage.getItem('user_token')),
            'X-Store-Id': JSON.parse(localStorage.getItem("store_id")),
            'X-Store-Token': JSON.parse(localStorage.getItem("store_token")),
            'Content-Type': 'application/json'
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Products'],
    }),



  }),

})

export const { useGetAllProductsQuery, useGetAllProductPriceQuery, useDeleteProductMutation, useUpdateProductMutation } = productsApi