import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
import { OrdersApi } from '../apis/orders';
import { productsApi } from '../apis/products';
import { payoutApi } from '../apis/payout';

export const store = configureStore({
  reducer: {
    [OrdersApi.reducerPath]: OrdersApi.reducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [payoutApi.reducerPath]: payoutApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(OrdersApi.middleware).concat(productsApi.middleware).concat(payoutApi.middleware)
})

setupListeners(store.dispatch)