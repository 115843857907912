import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Modal, DropdownButton } from 'react-bootstrap'
import Barcode from "react-hooks-barcode";
import ReactToPrint from "react-to-print";
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactPaginate from 'react-paginate';
import TextField from "@material-ui/core/TextField";
import axios from 'axios';
import './index.scss';
import { useGetAllPayoutQuery } from '../../apis/payout';
import Pagination from './Pagination'
import TableSkeleten from './TableSkeleten'

function PayoutStatus() {
  const location = useLocation();
  const user_token = JSON.parse(localStorage.getItem("user_token"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));
  const [isLoading, setLoading] = useState(false)

  let pathName = window.location.pathname;
  const state = new URLSearchParams(location.search).get('state')

  let stateb = '';
  if (state === null) {
    stateb = '';
  } else {
    stateb = `state=${state}`
  }

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10

  const dataInfo = useGetAllPayoutQuery({ page: page, limit: limit, state: stateb }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const dataList = dataInfo?.data?.payout_line_items
  const pagination = dataInfo?.data?.meta?.pagination



  return (
    <div className="orderStyle">
      <div className="container">
        <div className="row cardList__margin">
          <div className="col-md-12">
            <div className="Table-header">
              <div className="cardList__overflow">
                <div className="col-md-3 box_ppdng mobSbox">
                  <Link to="/payout-status">
                    <div className={state === null ? 'boxcardW alert alert-info' : 'boxcard'}>
                      <div className="heading">
                        <div className="pendingOrdersCards">All</div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-3 box_ppdng mobSbox">
                  <Link to="/payout-status?state=pending">
                    <div className={state === 'pending' ? 'boxcardW alert alert-warning' : 'boxcard'}>
                      <div className="heading">
                        <div className="pendingOrdersCards">Pending</div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-3 box_ppdng">
                  <Link to="/payout-status?state=approved">
                    <div className={state === 'approved' ? 'boxcardW alert alert-success' : 'boxcard'}>
                      <div className="heading">
                        <div className="pendingOrdersCards">Approved</div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-md-3 box_ppdng">
                  <Link to="/payout-status?state=paid">
                    <div className={state === 'paid' ? 'boxcardW alert alert-success boxcardDB' : 'boxcard boxcardDB'}>
                      <div className="heading">
                        <div className="pendingOrdersCards">Paid</div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div> 
              {/* <div className="updateButton">
                <h5 className="listHeadng">Payout Status</h5>
              </div> */}
              <div className="dFlex-sb">
                <div className="dFlex">
                  <div className="mrgnRghtF">
                    <Button size="sm" variant="outline-secondary" disabled>List:&nbsp;{pagination?.total_count}</Button>
                  </div>
                  <div className="mr10">
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic">
                        {page}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ "minWidth": "20px" }}>
                        <Dropdown.Item as={Link} to={{ pathname: pathName, search: `?size=20&page=1&${stateb}` }}>20</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: pathName, search: `?size=40&page=1&${stateb}` }}>40</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: pathName, search: `?size=60&page=1&${stateb}` }}>60</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: pathName, search: `?size=80&page=1&${stateb}` }}>80</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: pathName, search: `?size=100&page=1&${stateb}` }}>100</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="newFlex">
                  <Pagination pagination={pagination} page={page} limit={limit} state={stateb} />
                </div>
              </div>
            </div>
            <div className="orders_tbaleView">
              <table className="table">
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Title</th>
                    <th>Price</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {dataInfo.isLoading &&
                    <TableSkeleten />
                  } 
                  {!dataInfo.isLoading &&
                    <>
                      {dataList?.map((item, i) =>
                        <tr key={i}>
                          <td className="table_sizeTD_Manage">{item.payout_line_itemable_id}</td>
                          <td className="table_sizeTD_Manage" >{item.order_item_title}</td>
                          <td className="table_sizeTD">
                            {item.currency_code}&nbsp; {item.amount}
                          </td>
                          <td>
                            <div className="product_TitleWrap">
                              {item.payout_schedule_state === 'pending' ?
                                <Button size="sm" variant="warning" className="dFlex" disabled> <small>Pending </small>
                                </Button> : null}
                              {item.payout_schedule_state === 'paid' ?
                                <Button size="sm" variant="success" className="dFlex" disabled> <small>Paid </small>
                                </Button> : null}
                              {item.payout_schedule_state === 'approved' ?
                                <Button size="sm" variant="info" className="dFlex" disabled> <small>Approved </small>
                                </Button> : null}
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  }
                </tbody>
              </table>
              {dataInfo.isSuccess && dataInfo.status !== 'pending' &&
                <>
                  {pagination?.total_count === 0 &&
                    <div className="row">
                      <div className="col-md-12">
                        <div className="storeCard emptyCard">
                          <div>
                            <h5 className="listHeadng emptyCard_Text">No data found!
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>}
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PayoutStatus;