import React from 'react';
import {BrowserRouter as Router,Switch,Route,Link} from "react-router-dom";
import {CSVLink, CSVDownload} from 'react-csv';
import { Navbar, Nav, NavDropdown, FormControl, Form, Button, Dropdown, Spinner } from 'react-bootstrap';
import './index.scss';


function CSVFormate() {


const csvData =[
  ['UID_SKU_number', 'title', 'price_in_local_currency', 'image_urls', 'description', 'features', 'category', 'weight_grams', 'in_stock', 'estimated_delivery_days', 'quantity', 'inventory_location', 'brand_name'],
  ['DC1234', 'title', '100', 'img1,img2,img3', 'description', 'features1,features2,features3', 'electronic', '100', 'true', '5', '10', 'UAE', 'desertcart']
];

  return (
  <div className="cvFormt">
    <div className="container">
      <div className="row newStore">
        <div className="col-md-10">
          <div className="csForcard">
            <h3>CSV Sample</h3>
            <div className="csDownld"> 
              <CSVLink data={csvData} filename={"DC_CSV_SAMPLE.csv"} target="_blank">
                <Button variant="warning" size="sm">Download CSV Sample</Button>
                </CSVLink>
            </div>
          <div className="cstxtb">
            <small>0% Commissions: You set the amount you want to receive for an item.</small>
          </div>
          <p className="csPera"><strong>1.&nbsp;</strong>UID_SKU_number &nbsp;&nbsp;&nbsp; 
          <small className="csSmall">UID_SKU_number, All should be unique</small></p>	
          <p className="csPera"><strong>2.&nbsp;</strong>title &nbsp;&nbsp;&nbsp; 
          <small className="csSmall">Product Title</small></p>	
          <p className="csPera"><strong>3.&nbsp;</strong>price_in_local_currency &nbsp;&nbsp;&nbsp; 
          <small className="csSmall">Price in local currency</small></p>	
          <p className="csPera"><strong>4.&nbsp;</strong>image_urls &nbsp;&nbsp;&nbsp;
          <small className="csSmall">img1,img2,img3</small></p>	
          <p className="csPera"><strong>5.&nbsp;</strong>description &nbsp;&nbsp;&nbsp;
          <small className="csSmall">Product Description</small></p>	
          <p className="csPera"><strong>6.&nbsp;</strong>features &nbsp;&nbsp;&nbsp;
          <small className="csSmall">Feature1,Features2,Feature3</small></p>	
          <p className="csPera"><strong>7.&nbsp;</strong>category &nbsp;&nbsp;&nbsp;
          <small className="csSmall">Products Category</small></p>	
          <p className="csPera"><strong>8.&nbsp;</strong>weight_grams &nbsp;&nbsp;&nbsp;
          <small className="csSmall">Weight in grams</small></p>		
          <p className="csPera"><strong>9.&nbsp;</strong>in_stock &nbsp;&nbsp;&nbsp;
          <small className="csSmall">true/false</small></p>	
          <p className="csPera"><strong>10.&nbsp;</strong>estimated_delivery_days &nbsp;&nbsp;&nbsp;
          <small className="csSmall">Estimated Delivery Days</small></p>	
          <p className="csPera"><strong>11.&nbsp;</strong>quantity	&nbsp;&nbsp;&nbsp;
          <small className="csSmall">Quantity</small></p>
          <p className="csPera"><strong>12.&nbsp;</strong>inventory_location &nbsp;&nbsp;&nbsp; 
          <small className="csSmall">USA</small></p>
          <p className="csPera"><strong>13.&nbsp;</strong>brand_name &nbsp;&nbsp;&nbsp; 
          <small className="csSmall">Brand Name</small></p>
           

        </div>     
      </div>
     </div>
     </div>
    </div>
  );
}

export default CSVFormate;
