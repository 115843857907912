import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Modal, DropdownButton } from 'react-bootstrap'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PaymentIcon from "@material-ui/icons/Payment";
import './index.scss';
import { useGetAllOrdersQuery, useAcceptOrderMutation, useRejectOrderMutation } from '../../apis/orders';
import Pagination from "../Orders/Pagination";
import TableSkeleten from "../Orders/TableSkeletenDashboard"
import moment from 'moment'
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import SortIcon from '@mui/icons-material/Sort';
function Dashboard(props) {

  const location = useLocation();

  const marketplace_shipping_state = new URLSearchParams(location.search).get('marketplace_shipping_state')
  const state = new URLSearchParams(location.search).get('state')

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10
  const sort = new URLSearchParams(location.search).get('sort') || null
  const states = `state=pending`

  let onSort;
  let sortParams;
  if (sort === null) {
    onSort = '&sort=sorted';
  } else {
    onSort = ``
  }
  if (sort === null) {
    sortParams = '';
  } else {
    sortParams = `&sort=${sort}`;
  }

  const dataInfo = useGetAllOrdersQuery({ page: page, limit: limit, states: states }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const dataList = dataInfo?.data?.order_items
  const pagination = dataInfo?.data?.meta?.pagination

  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9]/g;

  function sortAlphaNum(a, b) {
    const aA = a.merchant_location?.replace(reA, "");
    const bA = b.merchant_location?.replace(reA, "");
    if (aA === bA) {
      const aN = parseInt(a.merchant_location?.replace(reN, ""), 10);
      const bN = parseInt(b.merchant_location?.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }

  const dataArrayList = sort ? dataList?.slice().sort(sortAlphaNum) : dataList;


  const [AcceptOrder, updateresult] = useAcceptOrderMutation()
  const [RejectOrder, rejectresult] = useRejectOrderMutation()

  const [getDeatsilsShow, setDeatsilsShow] = useState(false);
  const [getViewData, setViewData] = useState("");
  function detailsClose() {
    setDeatsilsShow(false)
  }
  function displayProductDetails(data) {
    setDeatsilsShow('true')
    setViewData(data)
  }



  const [getAcceptModal, setAcceptModal] = useState(false);
  const [getReferenceNumber, setReferenceNumber] = useState('');
  function AcceptModal(data) {
    setAcceptModal(true)
  }

  function closeAcceptModal() {
    setAcceptModal(false)
  }





  // accept multiple orders
  const [getAcceptCheckbox, setAcceptCheckbox] = useState([]);
  function selectMultiple(data) {
    const resultaaaa = getAcceptCheckbox?.filter(itemsss => itemsss === data);
    if (resultaaaa?.length > 0) {
      return true
    } else {
      return false
    }
  }

  function selectAllOrder() {
    if (dataList?.length > 0) {
      if (getAcceptCheckbox?.length === dataList?.length) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
  const [getCounter, setCounter] = useState(0);
  function acceptSelectedOrder(data) {
    let counter = 0;
    data?.item_ids?.forEach(async (item) => {
      try {
        await AcceptOrder({ "order_item_id": item, "seller_reference_number": data.reference_number, "format": "json" })
        // setAcceptCheckbox([]);
      } catch (error) {
      }
      setCounter(counter++)


    })
  }

  useEffect(() => {
    if (getAcceptCheckbox?.length === getCounter + 1) {
      setAcceptModal(false);
      setAcceptCheckbox([]);
      if (getAcceptCheckbox?.length > 1) {
        window.open(`${window.location.pathname}?state=pending&page=${page}&size=${limit}`, "_self")
      } else {
        return <Redirect to={`${window.location.pathname}?state=pending&page=${page}&size=${limit}&update=update`} />
      }
    }
  }, [getCounter, dataList, updateresult])



  return (
    <div className="dashboardStyle orderStyle">

      <Modal className="mdlPopup invoice_Modal" show={getAcceptModal} onHide={closeAcceptModal} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reference Number</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <label>Enter your reference number</label>
              <div className="form-group">
                <input
                  style={{ border: '1px solid #eeee' }}
                  type="text"
                  className="form-control fileUpload dlrdflUpload"
                  value={getReferenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                {updateresult.isLoading || (updateresult.isFetching && !updateresult.isLoading) ?
                  <Button className="hghtbtn" variant="info" disabled style={{ width: '100%' }}>Please wait... Accepting - {getCounter}</Button> :
                  <Button className="hghtbtn" variant="info" style={{ width: '100%' }}
                    onClick={() => acceptSelectedOrder({ "item_ids": getAcceptCheckbox, "reference_number": getReferenceNumber })}
                  >Accept</Button>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>




      <Modal className="mdlPopup product_detailsModal" show={getDeatsilsShow} onHide={detailsClose} animation={false} centered>
      <Modal.Header closeButton>
          <Modal.Title>Order Details #{getViewData?.Order_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="det_Sty"><strong>Order Number:&nbsp;&nbsp; </strong>{getViewData?.order_number}</div>
            <div className="det_Sty"><strong>Order Item ID:&nbsp;&nbsp; </strong>{getViewData?.Order_id}</div>
            <div className="det_Sty"><strong>Product ID:&nbsp;&nbsp; </strong>{getViewData?.product_id}</div>
            <div className="det_Sty"><strong>Acceptance Expiration:&nbsp;&nbsp; </strong>
              {moment(getViewData?.acceptance_expiration_at * 1000).format('MMMM Do YYYY')}</div>
            <div className="det_Sty"><strong>Accepted at:&nbsp;&nbsp; </strong>
              {moment(getViewData?.accepted_at * 1000).format('MMMM Do YYYY')}</div>
            <div className="det_Sty"><strong>Currency:&nbsp;&nbsp; </strong>{getViewData?.currency_code}</div>
            <div className="det_Sty"><strong>Estimated Delivery Days:&nbsp;&nbsp; </strong>{getViewData?.estimated_delivery_days}</div>
            <div className="det_Sty"><strong>Order Place at:&nbsp;&nbsp; </strong>
              {moment(getViewData?.order_placed_at * 1000).format('MMMM Do YYYY')}</div>
            <div className="det_Sty">
              <span className="fsize12 mbSzAdd"><strong>Shipping to:</strong>&nbsp;{getViewData?.shipping_address_name},&nbsp;{getViewData?.shipping_address1},{getViewData?.shipping_address2},&nbsp;{getViewData?.shipping_address_city},&nbsp;{getViewData?.shipping_address_country}</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="container">
        <div className="row rw_MrgnCnts">
          <div className="col-md-4 box_pdngD">
            <Link to="/products">
              <div className="boxcard_sy">
                <div className="heading_sy">
                  <h5>Products Inventory</h5>
                </div>
                <div className="text">
                  <FormatListBulletedIcon className="pay_TextC" />
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4 box_pdngD">
            <Link to="/orders?state=pending">
              <div className="boxcard_sy">
                <div className="heading_sy">
                  <h5>All Orders</h5>
                </div>
                <div className="text">
                  <FormatListBulletedIcon className="pay_TextC" />
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-4 box_pdngD">
            <Link to="/payout-status">
              <div className="boxcard_sy">
                <div className="heading_sy">
                  <h5>Payout Status</h5>
                </div>
                <div className="text">
                  <PaymentIcon className="pay_TextC" />
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="row cardList__margin">
          <div className="col-md-12">
            <div className="Table-header">
              {/* {rejectresult.isLoading ? <div className="dcLoader1">&nbsp;</div> : <div style={{ "border": "3px solid #fff" }}></div>} */}
              <div className="updateButton">
                <h5 className="listHeadng">New Orders</h5>
              </div>
              <div className="dFlex-sb">
                <div className="dFlex">

                  <>
                    {getAcceptCheckbox.length > 0 ?
                      <Button size="sm" variant="success" className="mr10"
                        onClick={() => AcceptModal()}
                      >Accept - {getAcceptCheckbox?.length}</Button>
                      : <Button size="sm" variant="success" disabled className="mr10">Accept</Button>}
                  </>


                  <div className="mr10">
                    <Button size="sm" variant="outline-secondary" disabled>List:&nbsp;{pagination?.total_count}</Button>
                  </div>
                </div>
                <div className="dFlex">
                  <Pagination pagination={pagination} page={page} limit={limit} states={''} order_numbers={''} marketplace_shipping_states={''} shipping_address_id={''} sortParams={sortParams} />
                </div>
              </div>
            </div>
            <div className="orders_tbaleView">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ cursor: 'pointer' }} className={`${selectAllOrder() && 'alert alert-info'}`} for={`selectButtonAll`}
                      onClick={() => {
                        const indexxx = dataList?.map((item) => item.id)
                        if ((getAcceptCheckbox?.length > 0 && getAcceptCheckbox?.length !== dataList?.length) || getAcceptCheckbox?.length === 0) {
                          setAcceptCheckbox(indexxx)
                        } else {
                          setAcceptCheckbox([])
                        }
                      }}>
                      {getAcceptCheckbox?.length === dataList?.length && getAcceptCheckbox?.length != 0 ? 'Deselect All' : 'Select All'}
                      <input type="checkbox" className="chckSelect" value={getAcceptCheckbox}
                        style={{ display: 'none' }}
                        checked={selectAllOrder()} />
                    </th>
                    <th>SKU/UID</th>
                    <th>
                      <div className="Order-page-sort">
                        <span className="number">Aisle No.</span>
                        <Link
                        to={{ pathname: `${window.location.pathname}`, search: `?size=${limit}&page=${page}${onSort}` }}><span><SortIcon style={{color: sort ? '#000' : '#d0d0d0', fontSize: '1.3rem'}} /></span></Link>
                      </div> 
                    </th>
                    <th className="imgCenter">Images</th>
                    <th>Price</th>
                    <th>Title</th>
                    <th>Order Placed At</th>
                    <th className="sticky-column">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {dataInfo.isLoading &&
                    <TableSkeleten />
                  }
                  {!dataInfo.isLoading &&
                    <>
                      {dataArrayList?.map((item, i) =>
                        <tr key={i}>
                          <td className="table_sizeTD_Manage">
                            <div className="checkCenter">
                              <label className="selectButton checkBox_D" for={`selectButton${item.id}`}>
                                <input id={`selectButton${item.id}`} type="checkbox" className="chckSelect" value={item.id}
                                  checked={selectMultiple(item.id)}
                                  onClick={() => {
                                    const index = getAcceptCheckbox.findIndex(itema => itema === item.id)
                                    if (~index) { setAcceptCheckbox([...getAcceptCheckbox.slice(0, index), ...getAcceptCheckbox.slice(index + 1),]) }
                                    else { setAcceptCheckbox([...getAcceptCheckbox, item.id,]) }
                                  }} />
                              </label>
                            </div>
                          </td>

                          <td className="table_sizeTD_Manage ProView_details" style={{ textAlign: 'center' }}>
                            {props.store_id === 2 ?
                              <a href={`https://business.walmart.com/ip/seort/${item.store_uid}`} target="_blank">{item.store_uid}</a>
                              :
                              item.store_uid
                            }
                          </td>
                          <td className="table_sizeTD_Manage ProView_details" style={{ textAlign: 'center' }}>
                            {item.merchant_location && item.merchant_location}
                          </td>
                          <td className="table_sizeTD">
                            <div className="cardImage">
                              <img className="cardImages" src={item.image_urls[0] ? item.image_urls[0] : '/image-not-found.png'} width="120px" />
                            </div>
                          </td>

                          <td className="table_sizeTD table_sizeTD_Manage">
                            {item.currency_code}&nbsp; {item.price / 100}
                          </td>
                          <td>
                            <div className="product_TitleWrap ProView_details">
                              {item.title}
                            </div>
                          </td>
                          <td>{moment.utc(item.order_placed_at * 1000).local().format('MMMM Do YYYY, h:mm')}</td>

                          <td className="table_sizeTD_Manage sticky-column">
                            <span>
                              <Button size="sm" variant="info" className="status-buttons button-margin" onClick={() => displayProductDetails({
                                "Order_id": item.id,
                                "acceptance_expiration_at": new Date(item.acceptance_expiration_at * 1000).toISOString().slice(0, 19).replace('T', ' '),
                                "accepted_at": new Date(item.accepted_at * 1000).toISOString().slice(0, 19).replace('T', ' '),
                                "estimated_delivery_days": item.estimated_delivery_days,
                                "currency_code": item.currency_code,
                                "estimated_delivery_date": item.estimated_delivery_date,
                                "order_placed_at": item.order_placed_at,
                                "product_id": item.product_id,
                                "order_number": item.order_reference,
                                "shipping_address_name": item.shipping_address.name,
                                "shipping_address1": item.shipping_address.address_line_1,
                                "shipping_address2": item.shipping_address.address_line_2,
                                "shipping_address_city": item.shipping_address.city,
                                "shipping_address_country": item.shipping_address.country
                              })}>
                                <small>Details</small>
                              </Button>
                              {rejectresult?.isLoading && rejectresult?.originalArgs?.order_item_id === item.id ?
                                <Button size="sm" variant="danger" className="status-buttons" disabled >
                                  <small>Loading</small>
                                </Button>
                                :
                                <Button size="sm" variant="danger" className="status-buttons" onClick={() => RejectOrder({ "order_item_id": item.id, "reason": "seller_out_of_stock", "format": "json" })} >
                                  <small>Reject</small>
                                </Button>
                              }
                            </span>
                          </td>
                        </tr>
                      )}
                    </>
                  }
                </tbody>
              </table>
            </div>

            {dataInfo.isSuccess && dataInfo.status !== 'pending' &&
              <>
                {pagination?.total_count === 0 &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="storeCard emptyCard">
                        <div>
                          <h5 className="listHeadng emptyCard_Text">No data found!
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>}
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
