import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory } from "react-router-dom";
import { Button, Spinner } from 'react-bootstrap'
import './index.scss';

function IndexPage() {
  return (
    <div className="indexPage">
      <div className="container">
        <div className="row newStore">
          <div className="col-md-12 topImg">
            <div className="bgImage">
              <div className="bgContent">
                <h4 className="topHdngm">Sell to the rest
                  of the world</h4>
                <p>Reach customers in 163+ countries. Leave
                  the complexities of international shipping,
                  returns, and customers service to us.</p>
                  <Link to="/login">
                <Button variant="info" className="btnF">Get Started</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row btmRow">
          <div className="col-md-6 btmBxCont">
            <div className="btmbox">
              <div className="btmCimg">
                <img src="svg1.png" className="imgSize" />
              </div>
              <div className="btmContent">
                <h4 className="btmHdngm">Increase sales with localization and currency conversion</h4>
                <p className="btmContentP">Users can place order with their local currency, while you get paid in the currency of your choice.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 btmBxCont">
            <div className="btmbox">
              <div className="btmCimg">
                <img src="svg2.png" className="imgSize" />
              </div>
              <div className="btmContent">
                <h4 className="btmHdngm">Dedicated Account Managers</h4>
                <p className="btmContentP">Your account manager will help find opportunity for marketing and localization to increase your sales.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 btmBxCont">
            <div className="btmbox">
              <div className="btmCimg">
                <img src="svg3.png" className="imgSize" />
              </div>
              <div className="btmContent">
                <h4 className="btmHdngm">No-cross border fulfillment hassles</h4>
                <p className="btmContentP">desertcart purchases items from you in your country, which means you can avoid complexities of cross border logistics. Ship to any of our 4 consolidation hubs worldwide and we take care of the rest.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 btmBxCont">
            <div className="btmbox">
              <div className="btmCimg">
                <img src="svg4.png" className="imgSize" />
              </div>
              <div className="btmContent">
                <h4 className="btmHdngm">No Fees & Comissions</h4>
                <p className="btmContentP">Yes, you read that right. desertcart does not charge any fees or commissions. Simply list your selling price and you get that amount on every sale.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 btmBxCont">
            <div className="btmbox">
              <div className="btmCimg">
                <img src="svg5.png" className="imgSize" />
              </div>
              <div className="btmContent">
                <h4 className="btmHdngm">Decrease Fraud</h4>
                <p className="btmContentP">0 risk of fraudulent transactions and international charge-back risk.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 btmBxCont">
            <div className="btmbox">
              <div className="btmCimg">
                <img src="svg6.png" className="imgSize" />
              </div>
              <div className="btmContent">
                <h4 className="btmHdngm">Reach more customers</h4>
                <p className="btmContentP">Customers from 163+ countries will be able to purchase your products.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row newStore">
          <div className="col-md-12 topImg">
            <div className="bgBtmImage">
              <div className="bgBtmContent">
                <p>*Sign up now and start putting your
                  products in front of millions of customers</p>
                  <Link to="/login">
                <Button variant="info" className="btnB">Login/Signup</Button>
                </Link>
                <p>*We are currently accepting registration for selling from following countries</p>
                <p>United States, United Kingdom, India, United Arab Emirates</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexPage;
