import React from 'react';
import { Redirect, Link } from "react-router-dom";
import './index.scss';

function ChannelIntegration() {

  return (
    <div className="supportStyle">
    <div className="row newStore ">
      <div className="col-md-5">
        <div className="Scard BrdrRound">
        <div>
          <h4>Merchant integrations are underway and will be online soon.</h4>
        </div>
      </div>     
    </div>
    </div>
    </div>
  );
}

export default ChannelIntegration;
