import React, { useState, useEffect, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Button,
  Table,
  Spinner,
  Accordion,
  Card,
  Dropdown,
  Badge,
} from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactPaginate from "react-paginate";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import "./index.scss";
const fs = require("fs");
const AWS = require("aws-sdk");

function Documents() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm();
  const user_token = JSON.parse(localStorage.getItem("user_token"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));
  const user_email = JSON.parse(localStorage.getItem("user_email"));
  const store_name = JSON.parse(localStorage.getItem("store_name"));
  const store_url = JSON.parse(localStorage.getItem("store_url"));
  const store_country_code = JSON.parse(
    localStorage.getItem("store_country_code")
  );
  const store_state = JSON.parse(localStorage.getItem("store_state"));

  const randNumber = Math.floor(Math.random() * 1000000000);
  const BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_DOCS;

  const [selectedFile, setFiletoUpload] = React.useState("");
  const [getFileTypeSelect, setFileTypeSelect] = React.useState("");
  const [getStatus, setStatus] = React.useState("");
  const [isLoadingdoc, setLoadingdoc] = React.useState(false);

  const uploadFile = (fileName) => {
    setLoadingdoc(true);
    fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/store_product_lists/pre_signed_url.json?bucket=${BUCKET_NAME}&key=merchant/agreements/${store_id}/${randNumber}-${fileName.fileType}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/vnd.api+json; version:3.0',
        'X-User-Id': user_id,
        'X-User-Token': user_token,
        'X-Store-Id': store_id,
        'X-Store-Token': store_token,
        'Content-Type': 'application/json'
      }
    }).then((result) => {
      if (result.status === 200) {
        result.json().then((resp) => {
          fetch(resp.url, {
            method: "PUT",
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            body: fileName.searchQuery[0]
          }).then((result) => {

            if (result.status === 200) {
              setStatus("success");
            } else {
              setStatus("error");
            }
            setLoadingdoc(false);
          })
        })
      } else {
        setStatus("error");
      }
    })
  };



  return (
    <div className="documentStyle">
      <div className="container">
        <div className="row newStore">


          <div className="col-md-7">
            <div className="storeCardH BrdrRound">
              {isLoadingdoc ? <div className="dcLoader1">&nbsp;</div> : <div style={{ "border": "3px solid #fff" }}></div>}
              <div className="storeCardB">

                <div className="col-md-12">
                  <h5 className="hdngLeft">Upload Documents</h5>
                </div>
                <div className="suppotCardD">
                  <Link to="/Internal_Marketplace_Agreement_Final.pdf"
                    target="_blank" download >
                    <Button size="sm" variant="warning">Download Merchant Agreement</Button>
                  </Link>
                </div>

                {getStatus === "success" ? (
                  <small className="alert alert-success">
                    File has been added successfully
                  </small>
                ) : null}
                {getStatus === "error" ? (
                  <small className="alert alert-danger">File could not be added</small>
                ) : null}
                <form className="formAg" onSubmit={handleSubmit(uploadFile)}>
                  <label>Select Document Type</label>
                  <select className="form-control" onChange={(e) => setFileTypeSelect(e.target.file)}
                    id="fileType"
                    aria-invalid={errors.fileType ? "true" : "false"}
                    {...register("fileType", { required: true })}>
                    <option disabled selected value="">Select Type</option>
                    <option value="valid-Trade-license-or-incorporation-registration-certificate">
                      Valid Trade License or Incorporation Registration Certificate
                    </option>
                    <option value="valid-tax-certificate">
                      Valid Tax Certificate
                    </option>
                    <option value="Bank-statement-bearing-the-valid-companys-name-and-account-number">
                      Bank Statement bearing the valid company’s name and account number
                    </option>
                    <option value="valid-identification-document-of-business-owner">
                      Valid Identification document of Business Owner
                    </option>
                    <option value="authorized-signatory-document">
                      Authorized signatory document
                    </option>
                    <option value="signed-merchant-agreement">
                      Signed Merchant Agreement
                    </option>
                  </select>
                  <div className="dlvrd_File">
                    <div className="form-group FormGroupmrgn">
                      <label>Select File</label>
                      <input
                        type="file"
                        className="form-control fileUpload"
                        onChange={(e) => setFiletoUpload(e.target.file)}
                        id="searchQuery"
                        aria-invalid={errors.firstName ? "true" : "false"}
                        {...register("searchQuery", { required: true })}
                      />
                    </div>

                  </div>
                  <div className="txtcntr">
                    <Button className="hghtbtn" variant="info" type="submit">
                      Upload
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Documents;
