import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner } from 'react-bootstrap'
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import './index.scss';
import Navigation from '../Products/Navigation';

function NewProduct() {
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const user_token = JSON.parse(localStorage.getItem("user_token"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));
  const [isLoading, setLoading] = useState(false)
  const queryParams = new URLSearchParams(window.location.search);
  const productId = queryParams.get('id');

  const initProfile = {
    product: null,
    status: null
  };
  const [profile, setProfile] = useState(initProfile);

  const [store_uid, setSKU] = useState("");
  const [title, setTitle] = useState("");
  const [price_cents, setPriceCents] = useState("");
  const [category, setcategory] = useState("");
  const [weight_grams, setweight_grams] = useState("");
  const [in_stock, setin_stock] = useState("");
  const [estimated_delivery_days, setestimated_delivery_days] = useState("");
  const [quantity, setquantity] = useState("");
  const [location, setlocation] = useState("");
  const [brand_name, setbrand_name] = useState("");
  const [image_urlsa, setimage_urls] = useState("");
  const [featuresa, setfeatures] = useState("");
  const [description, setdescription] = useState("");
  const [HttpStatus, setHttpStatus] = useState([]);

  function createProduct(data) {
    let priceCents = data.list_price * 100;
    let image_urls = data.image_urls.toString().split(',');
    let features = data.features.toString().split(',');
    let blacklisted = ('false');
    let postData = {
      "store_parent_uid": data.store_uid, "store_uid": data.store_uid, "title": data.title, "price_cents": priceCents, "image_urls": image_urls,
      "description": data.description, "features": features, "category": data.category, "weight_grams": data.weight_grams, "list_price": data.list_price, "in_stock": data.in_stock, "estimated_delivery_days": data.estimated_delivery_days, "quantity": data.quantity, "location": data.location, "brand_name": data.brand_name, "blacklisted": blacklisted, "offer_reference": store_id, "format": 'json'
    }
    let products = { products: [postData] }
    setLoading(true);
    fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/store_product_lists`, {
      method: "POST",
      headers: {
        'Accept': 'application/vnd.api+json; version:3.0',
        'X-User-Id': user_id,
        'X-User-Token': user_token,
        'X-Store-Id': store_id,
        'X-Store-Token': store_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(products)
    }).then((result) => {
      result.json().then((resp) => {
        if (result.status === 201) {
          setHttpStatus('success')
          setSKU("")
          setTitle("")
          setPriceCents("")
          setcategory("")
          setweight_grams("")
          setin_stock("")
          setestimated_delivery_days("")
          setquantity("")
          setlocation("")
          setbrand_name("")
          setimage_urls("")
          setfeatures("")
          setdescription("")
          setLoading(false)
        }
        else {
          setHttpStatus('error')
          setLoading(false)
        }

        setLoading(false)
      })
    })
  }

  return (
    <div className="NewProduct orderStyle">
      <div className="container">
        <div className="row cardList__margin">
          <div className="col-md-12">
            <div className="Table-header">
              <Navigation />
              <div className="updateButton">
                <h5 className="listHeadng">New Product</h5>
              </div>
            </div>













            <div className="new-product-section">
              <div className="row cardList__margin">
                <div className="col-md-12">
                  <div>
                    {/* {isLoading ? <div className="dcLoader1">&nbsp;</div> : <div style={{ "border": "3px solid #fff" }}></div>} */}
                    <div className="storeCardB">
                      {HttpStatus === 'success' ?
                        <div>
                          <small className="alert alert-success">Product has been created and uploaded successfully.</small>
                        </div>
                        : null}
                      {HttpStatus === 'error' ?
                        <div>
                          <small className="alert alert-danger">Product could not be created.</small>
                        </div>
                        : null}
                      <form onSubmit={handleSubmit(createProduct)}>

                        <div className="dFlex-sb">
                          <div className="dFlex">
                            <div className="mr10">
                            </div>
                            <div className="mr10">
                            </div>
                          </div>
                          {isLoading ? <Button className="Button bmarginZero" size="sm" variant="primary" type="submit" disabled>Publishing...</Button> :
                            <Button className="Button bmarginZero" size="sm" variant="primary" type="submit" >Publish</Button>
                          }
                        </div>




                        <div className="row">
                          <div className="col-md-8 productMaincard">
                            <div className="form-group">
                              <label>Title</label>
                              <input className="form-control" type="text" name="title" defaultValue={title} aria-invalid={errors.title ? "true" : "false"}
                                {...register('title', { required: true })} />
                            </div>
                          </div>
                          <div className="col-md-4 productMaincard">
                            <div className="form-group">
                              <label>UID/SKU Number</label>
                              <input className="form-control" type="text" defaultValue={store_uid} name="store_uid" aria-invalid={errors.store_uid ? "true" : "false"}
                                {...register('store_uid', { required: true })} />
                            </div>
                          </div>
                          <div className="col-md-3 productMaincard">
                            <div className="form-group">
                              <label>Price (Local Currency)</label>
                              <input className="form-control" type="text" defaultValue={price_cents} name="list_price" aria-invalid={errors.list_price ? "true" : "false"}
                                {...register('list_price', { required: true })} />
                            </div>
                          </div>
                          <div className="col-md-3 productMaincard">
                            <div className="form-group">
                              <label>Category</label>
                              <input className="form-control" type="text" defaultValue={category} name="category" aria-invalid={errors.category ? "true" : "false"}
                                {...register('category', { required: true })} />
                            </div>
                          </div>
                          <div className="col-md-3 productMaincard">
                            <div className="form-group">
                              <label>Weight in Grams</label>
                              <input className="form-control" type="text" defaultValue={weight_grams} name="weight_grams" aria-invalid={errors.weight_grams ? "true" : "false"}
                                {...register('weight_grams', { required: true })} />
                            </div>
                          </div>
                          <div className="col-md-3 productMaincard">
                            <div className="form-group">
                              <label>In Stock (true/false)</label>
                              <select className="form-control" type="text" defaultValue={in_stock} name="in_stock" aria-invalid={errors.in_stock ? "true" : "false"}
                                {...register('in_stock', { required: true })} >
                                <option selected="selected" disabled="disabled">Select</option>
                                <option value="true">In stock</option>
                                <option value="false">Out of stock</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3 productMaincard">
                            <div className="form-group">
                              <label>Delivery Days</label>
                              <input className="form-control" type="text" defaultValue={estimated_delivery_days} name="estimated_delivery_days" aria-invalid={errors.estimated_delivery_days ? "true" : "false"}
                                {...register('estimated_delivery_days', { required: true })} />
                            </div>
                          </div>
                          <div className="col-md-3 productMaincard">
                            <div className="form-group">
                              <label>Quantity</label>
                              <input className="form-control" type="text" defaultValue={quantity} name="quantity" aria-invalid={errors.quantity ? "true" : "false"}
                                {...register('quantity', { required: true })} />
                            </div>
                          </div>
                          <div className="col-md-3 productMaincard">
                            <div className="form-group">
                              <label>Inventory Location</label>
                              <select className="form-control" type="text" defaultValue={location} name="location" aria-invalid={errors.location ? "true" : "false"}
                                {...register('location', { required: true })}>
                                <option value="" selected disabled>Select</option>
                                <option value="UAE">United Arab Emirates</option>
                                <option value="UK">United Kingdom</option>
                                <option value="India">India</option>
                                <option value="USA">United State</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-3 productMaincard">
                            <div className="form-group">
                              <label>Brand Name</label>
                              <input className="form-control" type="text" defaultValue={brand_name} name="brand_name" aria-invalid={errors.brand_name ? "true" : "false"}
                                {...register('brand_name', { required: true })} />
                            </div>
                          </div>
                          <div className="col-md-12 productMaincard">
                            <div className="form-group">
                              <label>Image URL's (https://img1,https://img2)</label>
                              <textarea className="form-control textArea" type="text" defaultValue={image_urlsa} name="image_urls" aria-invalid={errors.image_urls ? "true" : "false"}
                                {...register('image_urls', { required: true })} />
                            </div>
                          </div>
                          <div className="col-md-12 productMaincard">
                            <div className="form-group">
                              <label>Features (feature1,feature2)</label>
                              <textarea className="form-control textArea" type="text" defaultValue={featuresa} name="features" aria-invalid={errors.features ? "true" : "false"}
                                {...register('features', { required: true })} />
                            </div>
                          </div>
                          <div className="col-md-12 productMaincard">
                            <div className="form-group">
                              <label>Description</label>
                              <textarea className="form-control textArea" type="text" defaultValue={description} name="description" aria-invalid={errors.description ? "true" : "false"}
                                {...register('description', { required: true })} />
                            </div>
                          </div>
                        </div>
                        <div className="updateButton">
                          <div>
                            <h5 className="listHeadng">&nbsp;</h5>
                          </div>
                          <div>
                            {isLoading ? <Button className="Button bmarginZero" size="sm" variant="primary" type="submit" disabled>Publishing...</Button> :
                              <Button className="Button bmarginZero" size="sm" variant="primary" type="submit" >Publish</Button>
                            }
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewProduct;
