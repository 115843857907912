import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


export const payoutApi = createApi({
  reducerPath: 'payoutApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_DC_API}/api/`,
  }),

  tagTypes: ['Payout'],
  // keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({
    getAllPayout: builder.query({
      query: (data) => {
        return {
          url: `marketplace/payout_line_items?page[number]=${data.page}&page[size]=${data.limit}&${data.state}`,
          method: 'GET',
          headers: {
            'Accept': 'application/vnd.api+json; version:3.0',
            'X-User-Id': JSON.parse(localStorage.getItem('user_id')),
            'X-User-Token': JSON.parse(localStorage.getItem('user_token')),
            'X-Store-Id': JSON.parse(localStorage.getItem("store_id")),
            'X-Store-Token': JSON.parse(localStorage.getItem("store_token")),
            'Content-Type': 'application/json'
          }
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Payout'],
    }),


  
 


  }),

})

export const { useGetAllPayoutQuery } = payoutApi