import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import './index.scss';
import { Button, Table, Spinner, Dropdown } from 'react-bootstrap'
function StoreList() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const location = useLocation();
  const history = useHistory();
  const user_token = JSON.parse(localStorage.getItem("user_token"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));
  const user_email = JSON.parse(localStorage.getItem("user_email"));
  const [isstoreLoading, setstoreLoading] = useState(false)
  const [getstrLoginId, strLoginId] = useState("")
  const [AuthMsg, getStoreAuth] = useState("")
  const page = new URLSearchParams(location.search).get('page')
  const pageSize = (page == null ? '1' : page)
  function storeLogin(data) {
    strLoginId(data)
    setstoreLoading(true);
    fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/authorizations`, {
      method: "POST",
      headers: {
        'Accept': 'application/vnd.api+json; version:3.0',
        'X-User-Id': user_id,
        'X-User-Token': user_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        store_id: data
      })
    }).then((result) => {
      result.json().then((resp) => {
        if (result.status === 201) {
          localStorage.setItem("store_token", JSON.stringify(resp.authentication_token.token));
          localStorage.setItem("store_id", JSON.stringify(resp.authentication_token.store.id));
          localStorage.setItem("store_name", JSON.stringify(resp.authentication_token.store.name));
          localStorage.setItem("store_url", JSON.stringify(resp.authentication_token.store.url));
          localStorage.setItem("store_country_code", JSON.stringify(resp.authentication_token.store.country_code));
          localStorage.setItem("store_state", JSON.stringify(resp.authentication_token.store.state));
          localStorage.setItem("newM", JSON.stringify('newM'))
          history.push('/dashboard');
        }
        else {
          getStoreAuth('error')
        }
        setstoreLoading(false)

      })
    })
  }
  function userLogout() {
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_token");
    localStorage.clear();
    history.push('/login');
  }
  const [data, setData] = useState([])
  const [meta, setMeta] = useState([])
  const [isLoadingget, setLoadingget] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isLoadingCStore, setLoadingCStore] = useState(false)
  const [HttpStatus, setHttpStatus] = useState([])
  const [getStoreMsg, getStoredata] = useState("")
  useEffect(() => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/stores?page[number]=${pageSize}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/vnd.api+json; version:3.0',
        'X-User-Id': user_id,
        'X-User-Token': user_token,
        'Content-Type': 'application/json'
      }
    }).then((result) => {
      result.json().then((resp) => {
        setHttpStatus(result.status)
        if (result.status === 200) {
          setData(resp.stores);
          setMeta(resp.meta.pagination);
          setLoading(false)

        }
        else {
          getStoredata('error')
          setLoading(false)
        }
        setLoading(false)
      })
    })
  }, [page])
  const [name, setStoreName] = useState("");
  const [url, setStoreUrl] = useState("");
  const [country_code, setStoreCountry] = useState("");
  const [StoreCreateMsg, SroeCreteStatus] = useState("");
  const [getErrormsg, setErrormsg] = useState("");

  function createStore(data) {
    let storeData = { "name": data.name, "url": data.url, "country_code": data.country_code }
    setLoadingCStore(true);
    fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/registrations`, {
      method: "POST",
      headers: {
        'Accept': 'application/vnd.api+json; version:3.0',
        'X-User-Id': user_id,
        'X-User-Token': user_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(storeData)
    }).then((result) => {
      result.json().then((resp) => {
        setHttpStatus(result.status);
        if (result.status === 201) {
          SroeCreteStatus('success')
          setLoadingCStore(false)
          history.push('/dashboard');
          setStoreName("")
          setStoreUrl("")
          setStoreCountry("")
        }
        else {
          SroeCreteStatus('error')
          setLoadingCStore(false)
          setErrormsg(resp.errors.url)
        }
      })
      setLoadingCStore(false)
    })
  }
  return (
    <div className="storeStyle">
      {!user_token && !user_id ? <Redirect to="./login"></Redirect> : null}

      <div className="container bgStore">
        <div className="row newStore">
          <div className={`col-md-${(meta.total_count === 0 ? 7 : 12)}`}>
            <div className="storeCardH BrdrRound">
              {isLoading ? <div className="dcLoader1">&nbsp;</div> : <div style={{ "border": "3px solid #fff" }}></div>}
              <div className="storeCardB">
                {meta.total_count === 0 ?
                  <div className="storeListPdng">
                    <h4>Welcome to Merchant Central</h4>
                    <p>You're profile doesn't have any registered stores. You may create one by filling the form below:</p>
                    <div className="flexDisStr">
                      <div className="flexDisStrSub">
                        {StoreCreateMsg === 'success' ?
                          <div>
                            <div>
                              <h5 className="alert alert-success">Store has been created successfully.</h5>
                            </div>
                          </div>
                          : null}
                        {StoreCreateMsg === 'error' ?
                          <div>
                            <div>
                              <h5 className="alert alert-danger">Kindly create an alternative Store Name, as it {getErrormsg}</h5>
                            </div>
                          </div>
                          : null}
                        <div className="storeCardtextNew">
                          <h5 className="listHeadngNew">Create New Store</h5>
                        </div>
                        <form onSubmit={handleSubmit(createStore)}>
                          <div className="form-group">
                            <label>Store Name</label>
                            <input tyep="text" className="form-control" name="name" defaultValue={name} aria-invalid={errors.name ? "true" : "false"}
                              {...register('name', { required: true })} />
                          </div>
                          <div className="form-group">
                            <label>Store URL</label>
                            <input tyep="text" className="form-control" name="url" defaultValue={url} aria-invalid={errors.url ? "true" : "false"}
                              {...register('url', { required: true })} />
                          </div>
                          <div className="form-group">
                            <label>Country</label>
                            <select tyep="text" className="form-control" name="country_code" defaultValue={country_code} aria-invalid={errors.country_code ? "true" : "false"}
                              {...register('country_code', { required: true })} >
                              <option disabled selected>Select Store Location </option>
                              <option value="GB">	Great Britain</option>
                              <option value="IN">	India</option>
                              <option value="AE">	United Arab Emirates</option>
                              <option value="US">	United States Of America</option>
                            </select>
                          </div>
                          <div className="form-group">
                            {isLoadingCStore ? <Button variant="info" className="Button Cbtn" type="submit" disabled>Please wait...</Button> : <Button variant="info" className="Button Cbtn" type="submit">Create</Button>}

                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  : null}
                <div>
                  {meta.total_count > 0 ?
                    <div>

                      <div className="storeListPdng">
                        <div className="pagiStyleU">
                          <div className="storeCardtext">
                            <h5 className="listHeadng">Login into your store</h5>
                          </div>

                          <div className="pagiStyle">
                            {meta.prev_page == null || meta.total_pages >= pageSize ?
                              <div className="subPagi">
                                {meta.prev_page == null ?
                                  <Button variant="outline-secondary" disabled size="sm">First</Button> : null}
                                <Link to={{ pathname: "/store-list", search: `?page=1` }}>
                                  {meta.prev_page != null ? <Button variant="outline-secondary" size="sm">First</Button> :
                                    null}
                                </Link>
                              </div>
                              : null}
                            {meta.prev_page == null || meta.total_pages >= pageSize ?
                              <div className="subPagi">
                                {meta.prev_page == null ?
                                  <Button variant="outline-secondary" disabled size="sm">Previous</Button> : null}
                                <Link to={{ pathname: "/store-list", search: `?page=${meta.prev_page}` }}>
                                  {meta.prev_page != null ? <Button variant="outline-secondary" size="sm">Previous</Button> :
                                    null}
                                </Link>
                              </div>
                              : null}
                            <div className="subPagi">
                              <Button variant="secondary" size="sm" disabled>{meta.current_page}</Button>
                            </div>
                            {meta.next_page == null || meta.total_pages >= pageSize ?
                              <div className="subPagi">
                                {meta.next_page == null ?
                                  <Button variant="outline-secondary" disabled size="sm">Next</Button> : null}
                                <Link to={{ pathname: "/store-list", search: `?page=${meta.current_page + 1}` }}>
                                  {meta.next_page != null ?
                                    <Button variant="outline-secondary" size="sm">Next</Button> : null}
                                </Link>
                              </div>
                              : null}
                            {meta.next_page == null || meta.total_pages >= pageSize ?
                              <div className="subPagi">
                                {meta.next_page == null ?
                                  <Button variant="outline-secondary" disabled size="sm">Last</Button> : null}
                                <Link to={{ pathname: "/store-list", search: `?page=${meta.total_pages}` }}>
                                  {meta.next_page != null ?
                                    <Button variant="outline-secondary" size="sm">Last</Button> : null}
                                </Link>
                              </div>
                              : null}
                          </div>
                        </div>
                        {getStoreMsg === 'error' ?
                          <h5 className="alert alert-danger">An error occured when searching for the store.</h5>
                          : null}
                        {AuthMsg === 'error' ?
                          <small className="alert alert-danger">Could not complete login into store. Kindly try again later.</small>
                          : null}
                      </div>

                      <Table responsive="sm" className="storeTable">
                        <thead>
                          <tr>
                            <th style={{ paddingLeft: "30px" }}>ID</th>
                            <th>Name</th>
                            <th>URL</th>
                            <th>Country Code</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, i) =>
                            <tr key={i}>
                              <td style={{ paddingLeft: "30px" }}>{item.id}</td>
                              <td>{item.name}</td>
                              <td>{item.url}</td>
                              <td>{item.country_code}</td>
                              <td>
                                {item.state === 'pending' ?
                                  <div className="flxCntr">
                                    <Button size="sm" variant="warning" disabled>{item.state}</Button></div> :
                                  <div className="flxCntr">
                                    {isstoreLoading && getstrLoginId === item.id ? <Button size="sm" className="lgnSpinB" variant="success" disabled>Please wait...</Button> :
                                      <Button size="sm" onClick={() => storeLogin(item.id)} variant="success">Login</Button>}
                                  </div>
                                }
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StoreList;