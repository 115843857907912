import React, { useState, useEffect, Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  Redirect,
  useLocation,
} from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  FormControl,
  Form,
  Button,
  Dropdown,
} from "react-bootstrap";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import PaymentIcon from "@material-ui/icons/Payment";
import SettingsIcon from "@material-ui/icons/Settings";
import ChatIcon from "@material-ui/icons/Chat";
import ImageIcon from "@material-ui/icons/Image";
import SyncIcon from "@material-ui/icons/Sync";
import HelpIcon from '@material-ui/icons/Help';
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import NotificationsIcon from "@material-ui/icons/Notifications";
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import StoreIcon from '@material-ui/icons/Store';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import "./index.scss";
import { Navigation } from "react-minimal-side-navigation"; 
import { useForm } from "react-hook-form";

import { useGetOrdersLengthQuery } from '../../apis/orders';

function HeaderMenu(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(); 
  const history = useHistory();
  const location = useLocation();
  const user_token = JSON.parse(localStorage.getItem("user_token"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));
  const user_email = JSON.parse(localStorage.getItem("user_email"));
  const store_name = JSON.parse(localStorage.getItem("store_name"));
  const store_country_code = JSON.parse(localStorage.getItem("store_country_code"));
  let storeNamea = store_name ? store_name.split(" ")[0] : null;
  let storeName = store_name ? storeNamea.slice(0, 7) : null;
  function userLogout() {
    localStorage.clear();
    history.push("/login");
  }
  function storeLogout() {
    localStorage.removeItem("store_token");
    localStorage.removeItem("store_id");
    // localStorage.clear();
    history.push("/store-list");
  }

  let expandMoreIcon = <ExpandMoreIcon />;
  let PaymentIcons = <PaymentIcon />;
  let Products = "Products";
  let Orders = "Orders";
  let Shipment = "Payment";
  let Settings = "Settings";

  let products = "";
  let productsTitle = [PaymentIcons, Settings];

  const [StoreCreateMsg, setSearchQuery] = useState("");
  function onSubmit(data, e) {
    history.push(`/products?query=${data.searchQuery}`);
    e.target.reset();
    setSearchQuery("");
  }

  function fulfillmentStore(){
    window.open('https://fulfillment.desertcart.com', '_blank')
  }

 
   
 
  const [getDatasss, setDatasss] = useState();
  const dataLength = useGetOrdersLengthQuery({change: window.location.href}, { count: 1 }, { refetchOnMountOrArgChange: true })
  useEffect(() => {
    if (window.location.href || window.location.pathname) {
      setDatasss(dataLength?.data?.order_items?.length)
    } else {
      setDatasss(dataLength?.data?.order_items?.length)
    }
  }, [window.location.pathname, window.location.href, setDatasss, getDatasss, dataLength])


  
  return (
    <div className="headerMenu">
      <Navigation
        activeItemId="/dashboard"
        items={[
          {
            title: (
              <div className="navTophdng">
                {" "}
                <span className="strNumnv">
                  {storeName}&nbsp;{" "}
                  <span className="strNamenv">#{store_id}</span>
                </span>{" "}
                <div className="rotatebeta">Beta</div>
              </div>
            ),
          },
          {
            title: (
              <Link to="./dashboard">
                <DashboardIcon className="dromMainIcon" />{" "}
                <span>Dashboard</span>
              </Link>
            ),
            itemId: "/dashboard",
          },
          {
            title: (
              <Link to="./channel-integration">
                <SyncIcon className="dromMainIcon" /> <span>Integrations</span>
              </Link>
            ),
            itemId: "/channel-integration",
          }, 
          {
            title: (
              <Link to="/products">
                <FormatListBulletedIcon className="dromMainIcon" />{" "}
                <span>Products</span>
              </Link>
            ),
            itemId: "/products",
          },
          {
            title: (
              <Link to="/orders?state=pending">
                <FormatListBulletedIcon className="dromMainIcon" />{" "}
                <span>Orders</span>
              </Link>
            ),
            itemId: "./orders?state=pending",
          },
          {
            title: (
              <Link to="/payout-status">
                <PaymentIcon className="dromMainIcon" />{" "}
                <span>Payout Status</span>
              </Link>
            ),
            itemId: "./payout-status",
          },
          {
            title: (
              <Link to="./multiple-shipment">
                <LocalShippingIcon className="dromMainIcon" />{" "}
                <span>Create Shipment</span>
              </Link>
            ),
            itemId: "./multiple-shipment",
          },
          {
            title: (
              <Link to="./new-store">
                <CreateNewFolderIcon className="dromMainIcon" />{" "}
                <span>New Store</span>
              </Link>
            ),
            itemId: "/new-store",
          },
          {
            title: (
              <Link to="./documents">
                <FileCopyIcon className="dromMainIcon" />{" "}
                <span>Documents</span>
              </Link>
            ),
            itemId: "/documents",
          },
          {
            title: (
              <div className="dromMainD">
                <SettingsIcon className="dromMainIcon" /> <span>Settings</span>
              </div>
            ),
            itemId: "/settings",
            subNav: [
              {
                title: (
                  <Link to="./setting">
                    <FiberManualRecordIcon className="dropIcons" />
                    Profile
                  </Link>
                ),
                itemId: "/setting",
              },
              {
                title: (
                  <Link to="./csv-formate">
                    <FiberManualRecordIcon className="dropIcons" />
                    CSV Format
                  </Link>
                ),
                itemId: "/csv-formate",
              },
            ],
          },
          {
            title: (
              <Link to="./media">
                <ImageIcon className="dromMainIcon" /> <span>Media</span>
              </Link>
            ),
            itemId: "/media",
          },
          {
            title: (
              <Link to="./faqs">
                <ChatIcon className="dromMainIcon" /> <span>FAQ</span>
              </Link>
            ),
            itemId: "/faqs",
          },
          {
            title: ( store_country_code === 'AE' ? 
              <div className="divStyleF" onClick={fulfillmentStore}>
                <StoreIcon className="dromMainIcon" /> <span>Fulfillment</span>
              </div> : null
            ),
            itemId: "/fulfillment",
          },
        ]}
      />
      <div className="supportTag">
        <Link to="./support">
          <div className="bg__color"><HelpIcon />&nbsp;Help/Support</div>
        </Link>
      </div>
      <Navbar className="topNavBar" bg="white" expand="lg">
      <div className="flexAlign">
        <Navbar.Brand as={Link} to="/dashboard">
          <img
            className="headerLogo"
            src="https://cdn.desertcart.com/static/media/logo.5821c90d.png"
            width="40px"
          ></img>
        </Navbar.Brand>
        <form onSubmit={handleSubmit(onSubmit)} className="hideOnMob">
          <div className="searchHeader">
            <input
              type="text"
              placeholder="Search Products..."
              className="form-control srchrds100"
              onChange={(e) => setSearchQuery(e.target.value)}
              id="searchQuery"
              aria-invalid={errors.firstName ? "true" : "false"}
              {...register("searchQuery", { required: true })}
            />
            <Button variant="info" className="searchIcon" type="submit">
              <SearchIcon className="search__Icon" />
            </Button>
          </div>
        </form>
        </div> 
          <div className="flexAlign">
          <Link to="/bulk-upload">
            <Button variant="info" size="sm" className="BKstyle">
              <span className="hideOnMob">Import CSV</span>
              <span className="hideOnDesk"><CloudUploadIcon /></span>
            </Button>
          </Link>
          <Button onClick={storeLogout} variant="secondary" size="sm"  className="rds100" >
            <span className="hideOnMob">Change Store</span>
            <span className="hideOnDesk"><StoreIcon /></span> 
          </Button>

          <Dropdown className="bdrLft">
            <Dropdown.Toggle as={Link} to="/orders?state=pending"
              variant="link"
              id="dropdown-basic"
              className="accountDropdown"
            >
              <span className="profileAccount">
                <NotificationsIcon className="ntColor" />
              </span>
              {getDatasss > 0 ? (
                <span className="rejected_ordershdr">{getDatasss }</span>
              ) : null}
            </Dropdown.Toggle> 
          </Dropdown>

          <Dropdown className="bdrLft">
            <Dropdown.Toggle
              variant="link"
              id="dropdown-basic"
              className="accountDropdown"
            >
              <span className="profileAccount ntRight">
                {user_email ? user_email[0] : null}
              </span> 
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropUI">
              <div className="dropHint"></div>
              <div className="hdrDropDsgn">
              <div className="headerEmaild">{user_email}</div>
              </div>
              <Dropdown.Item as={Link} to="./setting">
                Profile
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="./documents">
                Documents
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/new-store">
                Create a new store
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/channel-integration">
                Channel Integration
              </Dropdown.Item>
              <Dropdown.Item onClick={storeLogout}>Change Store</Dropdown.Item>
              <Dropdown.Item as={Link} to="./support">
                Support
              </Dropdown.Item>
              <Dropdown.Item onClick={userLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> 
          </div>
      </Navbar>

      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

export default HeaderMenu;
