import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
function Pagination(props) {
  const location = useLocation();
  return (
    <>
      <div className="pagination">
        {props.pagination?.prev_page === null && <Link to="#" disabled>❮❮</Link>}
        {props.pagination?.prev_page !== null && <Link to={{ pathname: `${window.location.pathname}`, search: `?${props.queryLink}&page=1&size=${props.limit}&blacklisted=${props.deletedb}` }}>❮❮</Link>}

        {props.pagination?.prev_page === null && <Link to="#" disabled>❮</Link>}
        {props.pagination?.prev_page !== null && <Link to={{ pathname: `${window.location.pathname}`, search: `?${props.queryLink}&page=${props.pagination?.prev_page}&size=${props.limit}&blacklisted=${props.deletedb}` }}>❮</Link>}

        {props.pagination?.total_pages > 1 &&
<>
        {props.pagination?.prev_page !== null && <Link to={{ pathname: `${window.location.pathname}`, search: `?${props.queryLink}&page=${props.pagination?.prev_page}&size=${props.limit}&blacklisted=${props.deletedb}` }}>{props.pagination?.prev_page}</Link>}
        <Link to="#" className="active" disabled>{props.pagination?.current_page}</Link>
          {props.pagination?.next_page !== null && <Link to={{ pathname: `${window.location.pathname}`, search: `?${props.queryLink}&page=${props.pagination?.next_page}&size=${props.limit}&blacklisted=${props.deletedb}` }}>{props.pagination?.next_page}</Link>}
          </>
}

        {props.pagination?.next_page === null && <Link to="#" disabled>❯</Link>}
        {props.pagination?.next_page !== null && <Link to={{ pathname: `${window.location.pathname}`, search: `?${props.queryLink}&page=${props.pagination?.next_page}&size=${props.limit}&blacklisted=${props.deletedb}` }}>❯</Link>}

        {props.pagination?.next_page === null && <Link to="#" disabled>❯❯</Link>}
        {props.pagination?.next_page !== null && <Link to={{ pathname: `${window.location.pathname}`, search: `?${props.queryLink}&page=${props.pagination?.total_pages}&size=${props.limit}&blacklisted=${props.deletedb}` }}>❯❯</Link>}
      </div>
    </>
  );
}

export default Pagination;
