import React, { useState, useEffect, Fragment } from "react";
import {
 BrowserRouter as Router,
 Switch,
 Route,
 Link,
 useHistory,
 Redirect,
 useLocation,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
 Button,
 Table,
 Spinner,
 Accordion,
 Card,
 Dropdown,
 Badge,
} from "react-bootstrap";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ReactPaginate from "react-paginate";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import "./index.scss";
const fs = require("fs");
const AWS = require("aws-sdk");

function Setting() {
 const {
  register,
  handleSubmit,
  formState: { errors },
 } = useForm();
 const {
  register: register2,
  handleSubmit: handleSubmit2,
  formState: { errors: errors2 },
 } = useForm();
 const user_token = JSON.parse(localStorage.getItem("user_token"));
 const user_id = JSON.parse(localStorage.getItem("user_id"));
 const store_token = JSON.parse(localStorage.getItem("store_token"));
 const store_id = JSON.parse(localStorage.getItem("store_id"));
 const user_email = JSON.parse(localStorage.getItem("user_email"));
 const store_name = JSON.parse(localStorage.getItem("store_name"));
 const store_url = JSON.parse(localStorage.getItem("store_url"));
 const store_country_code = JSON.parse(
  localStorage.getItem("store_country_code")
 );
 const store_state = JSON.parse(localStorage.getItem("store_state"));

 const [getAccountNumber, setAccountNumber] = React.useState("");
 const [getAccountName, setAccountName] = React.useState("");
 const [getBranchName, setBranchName] = React.useState("");
 const [getIfscCode, setIfscCode] = React.useState("");
 const [getSwiftcOde, setSwiftcOde] = React.useState("");
 const [getIban, setIban] = React.useState("");

 const [getBankStatus, setBankStatus] = React.useState("");
 const [isLoadingbank, setLoadingbank] = React.useState(false);
 const [getResult, setResult] = React.useState("");
 function uploadBank(data) {
  let bankData = {
   "account_number": data.account_number,
   "account_name": data.account_name,
   "branch_name": data.branch_name,
   "ifsc_code": data.ifsc_code,
   "swift_code": data.swift_code,
   "iban": data.iban,
  };
  setLoadingbank(true);
  fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/bank_details`, {
   method: "POST",
   headers: {
    Accept: "application/vnd.api+json; version:3.0",
    "X-Store-Id": store_id,
    "X-Store-Token": store_token,
    "Content-Type": "application/json",
   },
   body: JSON.stringify(bankData),
  }).then((result) => {
   if (result.status === 201) {
    setBankStatus("success");
    setResult(result);
    // result.json().then((resp)=>{
    // })
    setAccountNumber("");
    setAccountName("");
    setBranchName("");
    setIfscCode("");
    setSwiftcOde("");
    setIban("");
   } else {
    setBankStatus("error");
   }
   setLoadingbank(false);
  });
 }
 const [data, setData] = useState([]);
 const [meta, setMeta] = useState([]);
 useEffect(() => {
  fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/bank_details`, {
   method: "GET",
   headers: {
    Accept: "application/vnd.api+json; version:3.0",
    "X-Store-Id": store_id,
    "X-Store-Token": store_token,
    "Content-Type": "application/json",
   },
  }).then((result) => {
   result.json().then((resp) => {
    if (result.status === 200) {
     setData(resp.bank_details);
     setMeta(resp.meta.pagination);
    }
   });
  });
 }, [getResult]);
 return (
  <div className="settingStyle">
   <div className="container">
    <div className="row newStore"> 
     <div className="col-md-7"> 
      <Accordion className="accordBottom">
       <Card className="tpCard BrdrRound">
        {/* <div className="proSettngs">{user_email}</div> */}
        <Accordion.Collapse className="show" eventKey="0">
         <Card.Body>
          <div className="col-md-12">
           <h5 className="hdngLeft">Profile Details</h5>
          </div>
          <div className="suppotCardD">
           <Link
            to="/Internal_Marketplace_Agreement_Final.pdf"
            target="_blank" download>
            <Button size="sm" variant="warning">Download Merchant Agreement</Button>
           </Link>
           <Link to="./documents">
            <Button size="sm" variant="primary" className="manageButtons">Upload Documents</Button>
           </Link>
          </div>
          <div>
           <div className="suppotCard">
            <span className="suppotSubcard">Email ID</span>
            <span className="supportText">{user_email}</span>
           </div>
           <div className="suppotCard">
            <span className="suppotSubcard">User ID</span>
            <span className="supportText">{user_id}</span>
           </div>
           <div className="suppotCard">
            <span className="suppotSubcard">Store ID</span>
            <span className="supportText">{store_id}</span>
           </div>
           <div className="suppotCard">
            <span className="suppotSubcard">Store Name</span>
            <span className="supportText">{store_name}</span>
           </div>
           <div className="suppotCard">
            <span className="suppotSubcard">Store URL</span>
            <span className="supportText">{store_url}</span>
           </div>
           <div className="suppotCard">
            <span className="suppotSubcard">Store Country</span>
            <span className="supportText">{store_country_code}</span>
           </div>
           <div className="suppotCard">
            <span className="suppotSubcard">Store Status</span>
            <span className="supportText">
             <Button variant="success" size="sm" disabled className="manageButtons"><small>{store_state}</small> </Button>
            </span>
           </div>
          
          </div>
         </Card.Body>
        </Accordion.Collapse>
       </Card>
      </Accordion>
      <Accordion>
       <Card className="btmCard BrdrRound">
        <Card.Header className="hdrPdng">
         <Accordion.Toggle className="clickAcc" as={Button}
          variant="link" eventKey="1">
          <div>Bank Details</div>
          <ExpandMoreIcon />
         </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
         <Card.Body>
          <div>
           {meta.total_count > 0 ? (
            <div>
             {data.map((item, i) => (
              <div key={i}>
               <div className="suppotCard">
                <span className="suppotSubcard">
                 Account Number
                </span>
                <span className="supportText">
                 {item.account_number}
                </span>
               </div>
               <div className="suppotCard">
                <span className="suppotSubcard">
                 Account Name
                </span>
                <span className="supportText">
                 {item.account_name}
                </span>
               </div>
               <div className="suppotCard">
                <span className="suppotSubcard">Branch Name</span>
                <span className="supportText">
                 {item.branch_name}
                </span>
               </div>
               <div className="suppotCard">
                <span className="suppotSubcard">IFSC Code</span>
                <span className="supportText">
                 {item.ifsc_code}
                </span>
               </div>
               <div className="suppotCard">
                <span className="suppotSubcard">Swift Code</span>
                <span className="supportText">
                 {item.swift_code}
                </span>
               </div>
               <div className="suppotCard">
                <span className="suppotSubcard">IBAN Number</span>
                <span className="supportText">{item.iban}</span>
               </div>
              </div>
             ))}
            </div>
           ) : null}
           {meta.total_count == 0 ? (
            <div>
             {getBankStatus === "success" ? (
              <div>
               <div>
                <h5 className="alert alert-success">
                 Bank details has been created successfully.
                </h5>
               </div>
              </div>
             ) : null}
             {getBankStatus === "error" ? (
              <div>
               <div>
                <h5 className="alert alert-danger">
                 Bank details could not be created.
                </h5>
               </div>
              </div>
             ) : null}
             <div className="storeCardtextNew">
              <h5 className="listHeadngNew">Bank Details</h5>
             </div>
             <form onSubmit={handleSubmit2(uploadBank)}>
              <div className="form-group">
               <label>Account Number</label>
               <input
                type="text"
                className="form-control"
                defauldValue={getAccountNumber}
                id="account_number"
                aria-invalid={errors2.account_number ? "true" : "false"}
                {...register2('account_number', { required: true })}
               />
              </div>
              <div className="form-group">
               <label>Account Name</label>
               <input
                type="text"
                className="form-control"
                defauldValue={getAccountName}
                id="account_name"
                aria-invalid={errors2.account_name ? "true" : "false"}
                {...register2('account_name', { required: true })}
               />
              </div>
              <div className="form-group">
               <label>Branch Name</label>
               <input
                type="text"
                className="form-control"
                defauldValue={getBranchName}
                id="branch_name"
                aria-invalid={errors2.branch_name ? "true" : "false"}
                {...register2('branch_name', { required: true })}
               />
              </div>
              <div className="form-group">
               <label>IFSC Code</label>
               <input
                type="text"
                className="form-control"
                defauldValue={getIfscCode}
                id="ifsc_code"
                aria-invalid={errors2.ifsc_code ? "true" : "false"}
                {...register2('ifsc_code', { required: true })}
               />
              </div>
              <div className="form-group">
               <label>Swift Code</label>
               <input
                type="text"
                className="form-control"
                defauldValue={getSwiftcOde}
                id="swift_code"
                aria-invalid={errors2.swift_code ? "true" : "false"}
                {...register2('swift_code', { required: true })}
               />
              </div>
              <div className="form-group">
               <label>IBAN</label>
               <input
                type="text"
                className="form-control"
                defauldValue={getIban}
                id="iban"
                aria-invalid={errors2.iban ? "true" : "false"}
                {...register2('iban', { required: true })}
               />
              </div>
              <div className="form-group">
               {isLoadingbank ? (
                <Button variant="info" className="udtngBnkdD"> <span>Updating...</span>
                 <Spinner className="udtngBnkd" animation="border" /></Button>
               ) : (
                <Button
                 variant="info"
                 className="Button" type="submit"
                >
                 Update
                </Button>
               )}
              </div>
             </form>
            </div>
           ) : null}
          </div>
         </Card.Body>
        </Accordion.Collapse>
       </Card>
      </Accordion>
     </div>
    </div>
   </div>
  </div>
 );
}

export default Setting;
