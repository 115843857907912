import React from 'react';
import {BrowserRouter as Router,Switch,Route,Link} from "react-router-dom";
import Dashboard from './components/Dashboard'; 
import Orders from './components/Orders'; 
import NewStore from './components/NewStore';
import Media from './components/Media';
import Documents from './components/Documents';
import Products from './components/Products/Products'; 
import BulkUpload from './components/Products/BulkUpload';
import CSVFormate from './components/Products/CSVFormate';
import Faqs from './components/Faqs';
import CreateShipment from './components/CreateShipment';
import MultipleShipment from './components/MultipleShipment';
import RequestPayment from './components/RequestPayment';
import StoreList from './components/StoreList';
import StoreSupport from './components/StoreList/support.js'; 
import Support from './components/Support';
import Setting from './components/Setting';
import UpdateProducts from './components/UpdateProducts';
import ChannelIntegration from './components/ChannelIntegration';
import NewProduct from './components/Products/NewProduct';
import PayoutStatus from './components/PayoutStatus';
import './App.css';
import Login from './components/Login';
import Logins from './components/Logins'; 
import Private from './Private';
import PrivateStore from './PrivateStore';
import NotFound from './NotFound';
import IndexPage from './IndexPage';



function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/dashboard">
            <Private cmp={Dashboard} />
          </Route>
          <Route exact path="/channel-integration">
            <Private cmp={ChannelIntegration} />
          </Route>
          <Route exact path="/multiple-shipment">
            <Private cmp={MultipleShipment} />
          </Route>
          <Route exact path="/products">
            <Private cmp={Products} />
          </Route> 
          <Route exact path="/bulk-upload">
            <Private cmp={BulkUpload} />
          </Route>
          <Route exact path="/csv-formate">
            <Private cmp={CSVFormate} />
          </Route>  
          <Route exact path="/payout-status">
            <Private cmp={PayoutStatus} />
          </Route>  
          <Route exact path="/orders">
            <Private cmp={Orders} />
          </Route> 
           <Route exact path="/faqs">
            <Private cmp={Faqs} />
          </Route>
          <Route exact path="/create-shipment">
            <Private cmp={CreateShipment} />
          </Route>
          <Route exact path="/request-payment">
            <Private cmp={RequestPayment} />
          </Route>
          <Route exact path="/support">
            <Private cmp={Support} />
          </Route>
          <Route exact path="/setting">
            <Private cmp={Setting} />
          </Route>
          <Route exact path="/documents">
            <Private cmp={Documents} />
          </Route>
          <Route exact path="/update-products">
            <Private cmp={UpdateProducts} />
          </Route>
          <Route exact path="/new-product">
            <Private cmp={NewProduct} />
          </Route>
           <Route exact path="/new-store">
            <Private cmp={NewStore} />
          </Route>
          <Route exact path="/media">
            <Private cmp={Media} />
          </Route>
          <Route exact path="/store-list">
            <PrivateStore cmpstr={StoreList} />
          </Route>
          <Route exact path="/store-support">
            <PrivateStore cmpstr={StoreSupport} />
          </Route> 
          <Route exact path="/logins">
            <Logins />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/">
            <IndexPage />
          </Route>
          <Route>
            <Private cmp={NotFound} />
          </Route>
        </Switch>
        </Router>
    </div>
  );
}

export default App;
