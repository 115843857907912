import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function TableSkeleten() {
  const location = useLocation();


  return (
    <>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
    </>
  )
}

export default TableSkeleten;