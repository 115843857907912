import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import { Button, Table, Spinner } from 'react-bootstrap'
import { Payment } from '@material-ui/icons';

function RequestPayment() {
  const history = useHistory();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const user_token = JSON.parse(localStorage.getItem("user_token"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));
  const user_email = JSON.parse(localStorage.getItem("user_email"));
  const departedAt = new Date().toLocaleString();
  const [StoreCreateMsg, SroeCreteStatus] = useState("");
  const [isLoading, setLoading] = useState(false)
  const [httpCodeStatus, setHttpStatus] = useState("")

  function paymentRequest(data) {
    setLoading(true);
    const htmlBody = `
    <html>
<head>
<title></title>
<link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700&amp;display=swap" rel="stylesheet" />
<style type="text/css">table,tbody,tr,th,td,p,small,a,span,div,ul,li,h1,h2,h3,h4,h5,h6	{font-family: "Lato", sans-serif;}
</style>
</head>
<body>
<table border="0" cellpadding="0" cellspacing="0" role="presentation" style="background: #eeeeee; margin: auto; width: 100%;" width="100%;">
<tbody>
<tr>
<td align="center">
<table style="max-width: 600px; background-color: #fff;">
<tbody>
<tr>
<td align="center" style="width: 600px;" width="600px">
<table style="width: 600px;">
<tbody style="background-color: #fff;">
<tr>
<td align="center" style="width: 100%; padding-top: 15px;" width="100%">
<h3 style="text-align: left; padding-left: 20px;">Dear Team;</h3>
<div style="padding: 20px; text-align: left;">
<span>
This is a automated message initiated by the merchant for release of payments related to the order item IDs mentioned.
Merchant Central
</span></div>
<div style="display: flex;align-items: center;justify-content: space-between; padding: 10px; width:600px;">
<div style="width:100%;">
<h3 style="margin: 0; text-align: left;font-weight:800; margin-bottom:5px;">Merchant Details:</h3>	
</div>
</div>
<div style="padding: 10px; text-align: left; width:600px;">
<h3>Merchant Massage</h3>
<span>${data.mMessage}</span></div>
<div style="padding: 10px; text-align: left;width:600px;">
<h3>Order Item Ids</h3>
<p>${data.orderItems}</p>
</div>
<div style="display: flex;align-items: center;justify-content: space-between; padding: 10px;width:600px;">
<div>
<div style="display: flex;align-items: center;">
<span style="margin-right: 5px;"><b>User ID:</b></span><span>${user_id}</span>
</div>
<div style="display: flex;align-items: center;">
<span style="margin-right: 5px;"><b>Store ID:</b></span><span>${store_id}</span>
</div>
<div style="display: flex;align-items: center;">
<span style="margin-right: 5px;"><b>Email ID:</b></span><span>${user_email}</span>
</div>
</div>
</div> 
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</body>
</html>
  `;
    var ses = require('node-ses'),
      client = ses.createClient({
        key: process.env.REACT_APP_SES_API_KEY,
        secret: process.env.REACT_APP_SES_SECRET_KEY,
        amazon: process.env.REACT_APP_SES_REGION_ID
      });
    client.sendEmail({
      to: 'finance@desertcart.com',
      from: 'Merchant Paymen #' + store_id + ' <merchant@desertcart.ae>',
      subject: 'Request For Payment #' + store_id,
      message: htmlBody
    }, function (err, data, res) {

      if (err) {
        SroeCreteStatus('error')
        // result.json().then((resp)=>{
        // }) 
      }
      else if (res) {
        SroeCreteStatus('success')
      }
      setLoading(false)
    });
  }

  return (
    <div className="RFPStyle">
      <div className="row newStore">
        <div className="col-md-6">
          <div className="storeCardH BrdrRound">
            {isLoading ? <div className="dcLoader1">&nbsp;</div> : <div style={{ "border": "3px solid #fff" }}></div>}
            <div className="storeCardB">
              {StoreCreateMsg === 'success' ?
                <div className="storeCard">
                  <div>
                    <h5 className="successHeadng">Request has been sent successfully. We will inform you about further details shortly. Thank you.</h5>
                  </div>
                </div>
                : null}
              {StoreCreateMsg === 'error' ?
                <div className="storeCard">
                  <div>
                    <h5 className="errorHeadng">Request could not be sent.</h5>
                  </div>
                </div>
                : null}
              <div className="storeCardtextNew">
                <h5 className="listHeadngNew">Request For Payment</h5>
              </div>
              <form onSubmit={handleSubmit(paymentRequest)}>
              <div className="form-group">
                <label>Order Item ID's i.e (12312,123123)</label>
                <textarea tyep="text" name="orderItems" className="form-control styleTextarea" id="orderItems"
                    aria-invalid={errors.orderItems ? "true" : "false"}
                    {...register('orderItems', { required: true })} />
              </div>
              <div className="form-group">
                <label>Message</label>
                <textarea tyep="text" name="mMessage" className="form-control styleTextarea" id="mMessage"
                    aria-invalid={errors.mMessage ? "true" : "false"}
                    {...register('mMessage', { required: true })} />
              </div>

              <div className="form-group">
                <Button variant="info" className="Button" type="submit">Send</Button>
              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestPayment;
