import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Accordion, Card, Spinner } from 'react-bootstrap'
import './index.scss';

function Faqs() {

  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true);
    fetch("./file.json").then((result) => {
      result.json().then((resp) => {
        setData(resp);
        setLoading(false)
      })
    })
  }, [])


  return (
    <div className="faqsStyle">
      <div className="row fqRow">
        <div className="col-md-8">
          
          <div className="storeCardH BrdrRound">
          {isLoading ? <div className="dcLoader1">&nbsp;</div> : null}
       <div className="storeCardB">
            <h3 className="fqh3">FAQ</h3>
            {/* {isLoading && <div className="spinLoading"><Spinner animation="border" /></div>} */}

           

            {data.map((item, i) =>
              <Accordion defaultActiveKey="0" key={i}>
                <Card >
                  <Card.Header className="fqheading">
                    <Accordion.Toggle as={Button} variant="link" eventKey="1" className="fqheadingb">
                      <span className="faqs_Question">{item.question}</span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body className="fqtxt">{item.answer}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            )}

          </div>
          </div>
        </div>
      </div>
    </div>
  )


}

export default Faqs;
