import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Modal, DropdownButton } from 'react-bootstrap'
import HeaderMenu from '../components/HeaderMenu';
import HelpIcon from '@material-ui/icons/Help';
import './index.scss';
import { saveAs } from "file-saver";
const fs = require('fs');
const AWS = require('aws-sdk');

function Private(props) {
  const Cmp = props.cmp;
  const history = useHistory();
  const location = useLocation();
  const user_token = JSON.parse(localStorage.getItem('user_token'));
  const user_id = JSON.parse(localStorage.getItem('user_id'));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));
  const user_email = JSON.parse(localStorage.getItem("user_email"));

  const page = new URLSearchParams(location.search).get('page')
  const pageSize = (page == null ? '1' : page)
  const size = new URLSearchParams(location.search).get('size')
  const pageSizeb = (size == null ? '10' : size)
  const deleted = new URLSearchParams(location.search).get('blacklisted')
  const deletedb = (deleted == null ? 'false' : deleted)

  const state = new URLSearchParams(location.search).get('state')
  const marketplace_shipping_state = new URLSearchParams(location.search).get('marketplace_shipping_state')



  let states = '';
  let marketplace_shipping_states = '';
  if (state === null) {
    states = ``
  } else {
    states = `state=${state}`
  }
  if (marketplace_shipping_state === null) {
    marketplace_shipping_states = '';
  } else {
    marketplace_shipping_states = `marketplace_shipping_state=${marketplace_shipping_state}`
  }



  let pathName = window.location.pathname;
 
 

  const [showA, setShowA] = useState(false);
  const [showR, setShowR] = useState(false);

  const randNumber = Math.floor(Math.random() * 1000000000)
  const BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_DOCS;

  const [getFiletoUpload, setFiletoUpload] = React.useState("");
  const [getIdUpload, setIdUpload] = React.useState("");
  const [getStatus, setStatus] = React.useState("");
  const [isLoadingdoc, setLoadingdoc] = React.useState(false)

  const uploadFileAttach = (fileName) => {
    setLoadingdoc(true);

    fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/store_product_lists/pre_signed_url.json?bucket=${BUCKET_NAME}&key=merchant/docs/${store_id}/${randNumber}-${fileName.ItemIds}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/vnd.api+json; version:3.0',
        'X-User-Id': user_id,
        'X-User-Token': user_token,
        'X-Store-Id': store_id,
        'X-Store-Token': store_token,
        'Content-Type': 'application/json'
      }
    }).then((result) => {
      if (result.status === 200) {
        result.json().then((resp) => {
          fetch(resp.url, {
            method: "PUT",
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            body: fileName.attachFile[0]
          }).then((result) => {
            if (result.status === 200) {
              setStatus('success')
              setFiletoUpload("")
              setIdUpload("")
              setLoadingdoc(true);
              let invoiceData = {
                "invoice_url": `merchant/docs/${store_id}/${randNumber}-${fileName.ItemIds}`,
                "order_item_ids": new Array(fileName.ItemIds)[0]
              }
              fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/payout_line_items`, {
                method: "POST",
                headers: {
                  'Accept': 'application/vnd.api+json; version:3.0',
                  'X-User-Id': user_id,
                  'X-User-Token': user_token,
                  'X-Store-Id': store_id,
                  'X-Store-Token': store_token,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(invoiceData)
              }).then((result) => {
                result.json().then((resp) => {
                  if (result.status === 201) {
                    setLoadingdoc(false)
                    setShowA(false) 
                  } else {
                    setLoadingdoc(false)
                  }
                })
              })
            } else {
              setStatus('error')
              setLoadingdoc(false);
            }
          })
        })
      }
    })
  };

 
  const [getOrderExLoa, setOrderExLoa] = React.useState(false);

  function ordersDownload() {
    setOrderExLoa(true);
    fetch(
      `${process.env.REACT_APP_DC_API}/api/marketplace/order_items/export.csv`,
      {
        method: "GET",
        headers: {
          Accept: "application/vnd.api+json; version:3.0",
          "X-User-Id": user_id,
          "X-User-Token": user_token,
          "X-Store-Id": store_id,
          "X-Store-Token": store_token,
          "Content-Type": "text/csv",
        },
        responseType: "blob",
      }
    )
      .then((response) => response.blob())
      .then((blob) => saveAs(blob, `OrdersItem_${store_id}.csv`))
      .then((result) => {
        setOrderExLoa(false);
      });
  }

 

  return (
    <div className="privateMS">
      <HeaderMenu />
      <div className="bdyLeft">
        {user_token && user_id && store_id && store_token ?
          <Cmp
            pageSize={pageSize}
            pageSizeb={pageSizeb}
            isLoadingdoc={isLoadingdoc}
            uploadFileAttach={uploadFileAttach}
            getStatus={getStatus}
            showA={showA}
            showR={showR}
            store_id={store_id}
            getOrderExLoa={getOrderExLoa}
            ordersDownload={ordersDownload}
            deletedb={deletedb}
          />
          :
          <Redirect to="/store-list"></Redirect>}
        <br /> 
      </div>
      {/* <div className="footerSty">
        <span className="footer_Hide">
          @{new Date().getFullYear()} &nbsp;desertcart Merchant Store</span>&nbsp;&nbsp;|&nbsp;&nbsp;V - 1.2.1</div> */}
    </div>
  );
}

export default Private;
