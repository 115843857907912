import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useHistory } from "react-router-dom";
import { Button, Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import logo from '../../dc-logo.png';
import Cookies from 'js-cookie'
import './index.scss';



function Logins() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false)
  const [HttpStatus, setHttpStatus] = useState([]);

  function loginMerchant(loginData) {
    let data = { "login": loginData.login, "password": loginData.password }
    setLoading(true);
    fetch(`${process.env.REACT_APP_DC_API}/api/sessions`, {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'HTTP-X-REAL-IP': '54.237.181.170',
        'REMOTE-ADDR': '54.237.181.170',
        'HTTP-X-FORWARDED-FOR': '54.237.181.170'
      },
      body: JSON.stringify(data)
    }).then((result) => {

      setHttpStatus(result.status);
      if (result.status === 201) {
        result.json().then((resp) => {
          localStorage.setItem("user_token", JSON.stringify(resp.authentication_token.token));
          localStorage.setItem("user_id", JSON.stringify(resp.authentication_token.user.id));
          localStorage.setItem("user_email", JSON.stringify(resp.authentication_token.user.email));
          Cookies.set('X-User-Token', resp.authentication_token.token)
          history.push('/dashboard');
        })
      }
      setLoading(false)
    })
  }
  let user_token = JSON.parse(localStorage.getItem('user_token'));
  let user_id = JSON.parse(localStorage.getItem('user_id'));

  return (
    <div className="loginStyle">
      {user_id && user_token ? <Redirect to="dashboard" /> : null}

      <div className="container">
        <div className="row loginRow">
          <div className="rowBox">

            <div className="loginHeader">
              <h3>Sign in</h3>
              <img src={logo} width="50px" />
            </div>
            {HttpStatus === 401 ?
              <div>
                <div>
                  <h5 className="alert alert-danger">Unable to login with your email or password</h5>
                </div>
              </div>
              : null}
            {HttpStatus === 422 ?
              <div>
                <div>
                  <h5 className="alert alert-danger">Blacklisted email service, try after sometime or user different  email id</h5>
                </div>
              </div>
              : null}
            {HttpStatus === 429 ?
              <div>
                <div>
                  <h5 className="alert alert-danger">Too many request try after sometime</h5>
                </div>
              </div>
              : null}
            <form onSubmit={handleSubmit(loginMerchant)}>
              <div className="form-group">
                <label className="lableLeft">Email Address</label>
                <input className="form-control" type="email" id="login"
                  aria-invalid={errors.login ? "true" : "false"}
                  {...register('login', { required: true })}
                />
              </div>
              <div className="form-group">
                <label className="lableLeft">Password</label>
                <input className="form-control" type="password" id="password"
                  aria-invalid={errors.password ? "true" : "false"}
                  {...register('password', { required: true })}
                />
              </div>

              {isLoading ? <Button className="Button lgnSpinB" variant="info" disabled>Please wailt...</Button> : <Button className="Button" variant="info" type="submit" >Sign in</Button>}
              <p>Don't remember your password?</p>
              <p><Link to="/login">Connect without a password</Link></p>
            </form>
          </div>


        </div>

      </div>
    </div>
  );
}

export default Logins;