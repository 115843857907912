import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import AddIcon from '@material-ui/icons/Add';
function Navigation(props) {
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query')
  const deleted = new URLSearchParams(location.search).get('blacklisted')
  const deletedb = (deleted == null ? 'false' : deleted)
  return (
    <>
     <div className="cardList__overflow">
        {query &&
         <div className="col-md-3 box_ppdng mobSbox">
          <div className='boxcardW alert alert-info'>
           <div className="heading">
            <div className="pendingOrdersCards">Search Results</div>
           </div>
          </div>
         </div>}
        {query &&
         <div className="col-md-3 box_ppdng mobSbox">
          <Link to={{ pathname: "/products" }}>
           <div className='boxcard'>
            <div className="heading">
             <div className="pendingOrdersCards">Products</div>
            </div>
           </div>
          </Link>
         </div>}
         
        {query === null &&
         <div className="col-md-3 box_ppdng mobSbox">
          <Link to={{ pathname: "/products", search: `?blacklisted=false&page=1&size=10` }}>
           <div className={window.location.pathname === '/products' && (deletedb === 'false' || deletedb === null )? 'boxcardW alert alert-info' : 'boxcard'}>
            <div className="heading">
             <div className="pendingOrdersCards">Active Products</div>
            </div>
           </div>
          </Link>
         </div>}
        {query === null &&
         <div className="col-md-3 box_ppdng mobSbox">
          <Link to={{ pathname: "/products", search: `?blacklisted=true&page=1&size=10` }}>
           <div className={deletedb === 'true' ? 'boxcardW alert alert-danger' : 'boxcard'}>
            <div className="heading">
             <div className="pendingOrdersCards">Deleted Products</div>
            </div>
           </div>
          </Link>
          </div>}
        <div className="col-md-3 box_ppdng mobSbox">
         <Link to="/bulk-upload">
          <div className={window.location.pathname === '/bulk-upload' ? 'boxcardW alert alert-info' : 'boxcard'}>
           <div className="heading">
            <div className="pendingOrdersCards">Bulk Upload</div>
           </div>
          </div>
         </Link>
        </div>
        <div className="col-md-3 box_ppdng">
         <Link to="/new-product">
          <div className={window.location.pathname === '/new-product' ? 'boxcardW boxcardDB alert alert-info' : 'boxcard boxcardDB'}>
           <div className="heading">
            <div className="pendingOrdersCards">New Products&nbsp;&nbsp;</div>
           </div>
           <AddIcon className="addIconSize" />
          </div>
         </Link>
        </div>
       </div>
    </>
  );
}

export default Navigation;
