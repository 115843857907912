import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function TableSkeleten() {
  const location = useLocation();
  const marketplace_shipping_state = new URLSearchParams(location.search).get('marketplace_shipping_state')
  const state = new URLSearchParams(location.search).get('state')


  let states = '';
  let marketplace_shipping_states = '';
  if (state === null) {
    states = '';
  } else {
    states = `state=${state}`
  }
  if (marketplace_shipping_state === null) {
    marketplace_shipping_states = '';
  } else {
    marketplace_shipping_states = `marketplace_shipping_state=${marketplace_shipping_state}`
  }
  const acceptedOrders = (state === 'accepted' && marketplace_shipping_state === 'pending_shipment' && true)
  const deliveredOrders = (state === 'accepted' && marketplace_shipping_state === 'delivered' && true)
  const allOrders = (state === null && marketplace_shipping_state === null && true)
  const inTransitOrders = (state === 'accepted' && marketplace_shipping_state === 'in_transit' && true)


  return (
    <>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
      <tr>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
        <td><div><Skeleton variant="rect" /></div></td>
      </tr>
    </>
  )
}

export default TableSkeleten;