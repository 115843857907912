import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner } from 'react-bootstrap'
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import './index.scss';

function NewStore() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const user_token = JSON.parse(localStorage.getItem("user_token"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));

  const [isLoading, setLoading] = useState(false)
  const [HttpStatus, setHttpStatus] = useState([])
  const [getStoreMsg, getStoredata] = useState("")
  const [name, setStoreName] = useState("");
  const [url, setStoreUrl] = useState("");
  const [country_code, setStoreCountry] = useState("");
  const [StoreCreateMsg, SroeCreteStatus] = useState("");
  const [getErrormsg, setErrormsg] = useState("");

  function createStore(data) {
    let storeData = { "name": data.name, "url": data.url, "country_code": data.country_code }
    setLoading(true);
    fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/registrations`, {
      method: "POST",
      headers: {
        'Accept': 'application/vnd.api+json; version:3.0',
        'X-User-Id': user_id,
        'X-User-Token': user_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(storeData)
    }).then((result) => {
      result.json().then((resp) => {
        setHttpStatus(result.status);
        if (result.status === 201) {
          SroeCreteStatus('success')
          setLoading(false)
          setStoreName("")
          setStoreUrl("")
          setStoreCountry("")
        }
        else {
          SroeCreteStatus('error')
          setLoading(false)
          setErrormsg(resp.errors && resp.errors.url)
        }
      })
      setLoading(false)
    })
  }

  return (
    <div className="newStore">
      <div className="container">
        <div className="row newStorerw">
          <div className="col-md-7">
          <div className="storeCardH BrdrRound">
            {isLoading ? <div className="dcLoader1">&nbsp;</div> : <div style={{ "border": "3px solid #fff" }}></div>}
            <div className="storeCardB">
              {StoreCreateMsg === 'success' ?
                <div>
                  <div>
                    <h5 className="alert alert-success">Store has been created successfully</h5>
                  </div>
                </div>
                : null}
              {StoreCreateMsg === 'error' ?
                <div>
                  <div>
                    <h5 className="alert alert-danger">Kindly create an alternative Store Name, as it {getErrormsg}</h5>
                  </div>
                </div>
                : null}
              <div className="storeCardtextNew">
                <h5 className="listHeadngNew">Create New Store</h5>
              </div>
              <form onSubmit={handleSubmit(createStore)}>
                <div className="form-group">
                  <label>Store Name</label>
                  <input tyep="text" className="form-control"
                    name="name" defaultValue={name} aria-invalid={errors.name ? "true" : "false"}
                    {...register('name', { required: true })} />
                </div>
                <div className="form-group">
                  <label>Store URL</label>
                  <input tyep="text" className="form-control" name="url" defaultValue={url} aria-invalid={errors.url ? "true" : "false"}
                    {...register('url', { required: true })} />
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <select tyep="text" className="form-control" name="country_code" defaultValue={country_code} aria-invalid={errors.country_code ? "true" : "false"}
                    {...register('country_code', { required: true })} >
                    <option disabled selected>Select Store Location </option>
                    <option value="GB">	Great Britain</option>
                    <option value="IN">	India</option>
                    <option value="AE">	United Arab Emirates</option>
                    <option value="US">	United States Of America</option>
                  </select>
                </div>
                <div className="form-group">
                  {isLoading ? <Button disabled variant="info" disabled className="Button crtBtn">Please wait...</Button> : <Button variant="info" className="Button crtBtn" type="submit">Create</Button>}
                  
                </div>
              </form>
            </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default NewStore;
