import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';
function Navigation(props) {
  
  const location = useLocation();
  const marketplace_shipping_state = new URLSearchParams(location.search).get('marketplace_shipping_state')
  const state = new URLSearchParams(location.search).get('state')
  let states = '';
  let marketplace_shipping_states = '';
  if (state === null) {
    states = '';
  } else {
    states = `state=${state}`
  }
  if (marketplace_shipping_state === null) {
    marketplace_shipping_states = '';
  } else {
    marketplace_shipping_states = `marketplace_shipping_state=${marketplace_shipping_state}`
  }

  const acceptedOrders = (state === 'accepted' && marketplace_shipping_state === 'pending_shipment' && true)
  const deliveredOrders = (state === 'accepted' && marketplace_shipping_state === 'delivered' && true)
  const allOrders = (state === null && marketplace_shipping_state === null && true)
  const inTransitOrders = (state === 'accepted' && marketplace_shipping_state === 'in_transit' && true)
  const pendingOrders = (state === 'pending' && marketplace_shipping_state === null && true)
  const rejectedOrders = (state === 'rejected' && marketplace_shipping_state === null && true)


  return (
    <>
      <div className="cardList__overflow">

        <div className="col-md-2 box_ppdng mobSbox">
          <Link to="/orders?state=pending">
            <div className={state === 'pending' && marketplace_shipping_state === null ? 'boxcardW alert alert-warning' : 'boxcard'}>
              <div className="heading">
                <div className="pendingOrdersCards"><span>Pending</span>
                  {((!props.dataInfo?.isLoading && props.dataInfo?.isFetching) && pendingOrders) &&
                    <div className="bouncing-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-2 box_ppdng mobSbox">
          <Link to="/orders?state=accepted&marketplace_shipping_state=pending_shipment">
            <div className={state === 'accepted' && marketplace_shipping_state === 'pending_shipment' ? 'boxcardW alert alert-info' : 'boxcard'}>
              <div className="heading">
                <div className="pendingOrdersCards"><span>Accepted</span>
                  {((!props.dataInfo?.isLoading && props.dataInfo?.isFetching) && acceptedOrders) &&
                    <div className="bouncing-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-2 box_ppdng">
          <Link to="/orders?state=accepted&marketplace_shipping_state=in_transit">
            <div className={state === 'accepted' && marketplace_shipping_state === 'in_transit' ? 'boxcardW alert alert-warning' : 'boxcard'}>
              <div className="heading">
                <div className="pendingOrdersCards"><span>InTransit</span>
                  {((!props.dataInfo?.isLoading && props.dataInfo?.isFetching) && inTransitOrders) &&
                    <div className="bouncing-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-2 box_ppdng mobSbox">
          <Link to="/orders?state=accepted&marketplace_shipping_state=delivered">
            <div className={state === 'accepted' && marketplace_shipping_state === 'delivered' ? 'boxcardW alert alert-success boxcardDB' : 'boxcard'}>
              <div className="heading">
                <div className="pendingOrdersCards"><span>Delivered</span>
                  {((!props.dataInfo?.isLoading && props.dataInfo?.isFetching) && deliveredOrders) &&
                    <div className="bouncing-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-2 box_ppdng mobSbox">
          <Link to="/orders?state=rejected">
            <div className={state === 'rejected' && marketplace_shipping_state === null ? 'boxcardW alert alert-danger' : 'boxcard'}>
              <div className="heading">
                <div className="pendingOrdersCards"><span>Rejected</span>
                  {((!props.dataInfo?.isLoading && props.dataInfo?.isFetching) && rejectedOrders) &&
                    <div className="bouncing-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-2 box_ppdng mobSbox">
          <Link to="/orders">
            <div className={state === null && marketplace_shipping_state === null ? 'boxcardW alert alert-info' : 'boxcard boxcardDB'}>
              <div className="heading">
                <div className="pendingOrdersCards"><span>All Orders</span>
                  {((!props.dataInfo?.isLoading && props.dataInfo?.isFetching) && allOrders) &&
                    <div className="bouncing-loader">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Navigation;
