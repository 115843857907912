import React from 'react';
import { Redirect, Link } from "react-router-dom";

function StoreSupport() {

  return (
    <div className="supportStyle">
    <div className="row newStore ">
      <div className="col-md-5">
        <div className="Scard BrdrRound">
        <div>
          <h4>Merchant Central Support</h4>
        </div>
        <span><strong>For any assistance regarding any issues/activation, <br />kindly contact us at:</strong></span>
        <div>
          <div className="suppotCard"> 
          <span className="supportText">merchant@desertcart.ae</span>
          </div> 
        </div>
      </div>     
    </div>
    </div>
    </div>
  );
}

export default StoreSupport;
