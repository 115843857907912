import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { Button, Table, Spinner, Dropdown } from 'react-bootstrap'
import './index.scss';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

function PrivateStore(props) {
 const location = useLocation();
 const history = useHistory();
 function userLogout() {
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_token");
  localStorage.clear();
  history.push('/login');
 }
 const Cmpcmpstr = props.cmpstr;
 let user_token = JSON.parse(localStorage.getItem('user_token'));
 let user_id = JSON.parse(localStorage.getItem('user_id'));
 const user_email = JSON.parse(localStorage.getItem("user_email"));
 let store_token = JSON.parse(localStorage.getItem('store_token'));
 let store_id = JSON.parse(localStorage.getItem('store_id')); 
 return (
  <div className="privateStr">
   <div className="strHdrBg">
    <div className="container storeHeadersub">
     <div>
      <img className="headerLogo" src="https://cdn.desertcart.com/static/media/logo.5821c90d.png" width="40px">
      </img>
     </div>
     <div>
      <Dropdown className="bdrLft">
       <Dropdown.Toggle
        variant="link"
        id="dropdown-basic"
        className="accountDropdown bdrLft"
       >
        <span className="profileAccount ntRight">
         {user_email}
        </span>
        <span className="headerEmail">
         <ExpandMoreIcon />
        </span>
       </Dropdown.Toggle>
       <Dropdown.Menu className="dropUI">
        <Dropdown.Item as={Link} to="/store-list">Store List</Dropdown.Item>
        <Dropdown.Item as={Link} to="/store-support">Support</Dropdown.Item>
        <Dropdown.Item onClick={userLogout}>Logout</Dropdown.Item>
       </Dropdown.Menu>
      </Dropdown>
     </div>
    </div>
   </div>


   {user_token && user_id ? <Cmpcmpstr /> : <Redirect to="login"></Redirect>}
   <br />
   <br />
   <div className="footerSty">
    <span className="footer_Hide">@{new Date().getFullYear()} &nbsp;Desertcart Merchant Store</span> &nbsp;&nbsp;|&nbsp;&nbsp;V - 1.2.1
   </div>
  </div>
 );
}

export default PrivateStore;
