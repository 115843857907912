import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner } from 'react-bootstrap'
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import './index.scss';

function UpdateProducts() {
  const history = useHistory();
  const user_token = JSON.parse(localStorage.getItem("user_token"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));
  const [isLoading, setLoading] = useState(false)
  const queryParams = new URLSearchParams(window.location.search);
  const productId = queryParams.get('id');

  const initProfile = {
    product: null,
    status: null
  };
  const [profile, setProfile] = useState(initProfile);

  const [store_uid, setStoreUid] = useState("");
  const [store_parent_uid, setStoreparent] = useState("");
  const [offer_reference, setofferrefernce] = useState("");
  const [blacklisted, setBlacklisted] = useState("");
  const [title, setTitle] = useState("");
  const [price_cents, setPriceCents] = useState("");
  const [category, setcategory] = useState("");
  const [weight_grams, setweight_grams] = useState("");
  const [in_stock, setin_stock] = useState("");
  const [estimated_delivery_days, setestimated_delivery_days] = useState("");
  const [quantity, setquantity] = useState("");
  const [location, setlocation] = useState("");
  const [brand_name, setbrand_name] = useState("");
  const [image_urlsa, setimage_urls] = useState("");
  const [featuresa, setfeatures] = useState("");
  const [description, setdescription] = useState("");

  const image_urls = image_urlsa.toString().split(',');
  const features = featuresa.toString().split(',');
  const priceCents = price_cents * 100;
  const listPrice = price_cents;


  async function getProfile() {
    setLoading(true);
    const response = await fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/products/${productId}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/vnd.api+json; version:3.0',
        'X-User-Id': user_id,
        'X-User-Token': user_token,
        'X-Store-Id': store_id,
        'X-Store-Token': store_token,
        'Content-Type': 'application/json'
      }
    });
    const json = await response.json();
    setProfile({
      product: json.product,
      status: response.status
    });
    setStoreparent(json.product.store_uid);
    setStoreUid(json.product.store_uid);
    setofferrefernce(json.product.offer_reference);
    setTitle(json.product.title);
    setPriceCents(json.product.sale_price.plain);
    setcategory(json.product.category);
    setweight_grams(json.product.weight_grams);
    setin_stock(json.product.in_stock);
    setestimated_delivery_days(json.product.estimated_delivery_days);
    setquantity(json.product.quantity);
    setlocation(json.product.location);
    setbrand_name(json.product.brand_name);
    setimage_urls(json.product.image_urls);
    setfeatures(json.product.features);
    setdescription(json.product.description);
    setBlacklisted("false");
    setLoading(false);
  }

  useEffect(() => {
    getProfile();
  }, []);
  const [HttpStatus, setHttpStatus] = useState([]);
  function updateUser() {
    let dataa = { "store_parent_uid": store_parent_uid, "store_uid": store_uid, "title": title, "price_cents": priceCents, "image_urls": image_urls, "description": description, "features": features, "category": category, "weight_grams": weight_grams, "list_price": listPrice, "in_stock": in_stock, "estimated_delivery_days": estimated_delivery_days, "quantity": quantity, "location": location, "brand_name": brand_name, "blacklisted": blacklisted, "offer_reference": offer_reference, "format": 'json' }
    let products = { products: [dataa] }
    setLoading(true);
    fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/store_product_lists`, {
      method: "POST",
      headers: {
        'Accept': 'application/vnd.api+json; version:3.0',
        'X-User-Id': user_id,
        'X-User-Token': user_token,
        'X-Store-Id': store_id,
        'X-Store-Token': store_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(products)
    }).then((result) => {
      result.json().then((resp) => {
        setHttpStatus(result.status);
        setLoading(false)
      })
    })
  }
  return (
    <div className="updateProduct">
      {HttpStatus === 201 ? <Redirect to="/products"></Redirect> : null}
      <div className="container">
        <div className="row cardList__margin">
          <div className="col-md-12">
            <div className="storeCardH BrdrRound">
              {isLoading ? <div className="dcLoader1">&nbsp;</div> : <div style={{ "border": "3px solid #fff" }}></div>}
              <div className="storeCardB">
                <div className="updateButton">
                  <div>
                    <h5 className="listHeadng">Update Product</h5>
                  </div>
                  {profile.status === 200 ?
                    <div>
                      <Button className="Button bmarginZero" size="sm" variant="primary" onClick={updateUser} >Update</Button>
                    </div>
                    : null}
                </div>
                {profile.status === 200 ?
                  <div className="row">
                    <div className="col-md-12 productMaincard">
                      <div className="form-group">
                        <label>Title</label>
                        <input className="form-control" type="text" name="title" defaultValue={profile.product.title}
                          onChange={(e) => setTitle(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-3 productMaincard">
                      <div className="form-group">
                        <label>Product price ({profile.product.sale_price.currency_code})</label>
                        <input className="form-control" type="text" name="list_price" defaultValue={profile.product.sale_price.plain}
                          onChange={(e) => setPriceCents(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-3 productMaincard">
                      <div className="form-group">
                        <label>Category</label>
                        <input className="form-control" type="text" name="category" defaultValue={profile.product.category}
                          onChange={(e) => setcategory(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-3 productMaincard">
                      <div className="form-group">
                        <label>Weight in Grams</label>
                        <input className="form-control" type="text" name="weight_grams" defaultValue={profile.product.weight_grams}
                          onChange={(e) => setweight_grams(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-3 productMaincard">
                      <div className="form-group">
                        <label>In Stock (true/false)</label>
                        <input className="form-control" type="text" name="in_stock" defaultValue={profile.product.in_stock}
                          onChange={(e) => setin_stock(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-3 productMaincard">
                      <div className="form-group">
                        <label>Estimated Delivery Days</label>
                        <input className="form-control" type="text" name="estimated_delivery_days" defaultValue={profile.product.estimated_delivery_days}
                          onChange={(e) => setestimated_delivery_days(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-3 productMaincard">
                      <div className="form-group">
                        <label>Quantity</label>
                        <input className="form-control" type="text" name="quantity" defaultValue={profile.product.quantity}
                          onChange={(e) => setquantity(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-3 productMaincard">
                      <div className="form-group">
                        <label>Inventory Location</label>
                        <input className="form-control" type="text" name="location" defaultValue={profile.product.location}
                          onChange={(e) => setlocation(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-3 productMaincard">
                      <div className="form-group">
                        <label>Brand Name</label>
                        <input className="form-control" type="text" name="brand_name" defaultValue={profile.product.brand_name}
                          onChange={(e) => setbrand_name(e.target.value)} />
                      </div>
                    </div>
                    <div className="col-md-12 productMaincard">
                      <div className="form-group">
                        <label>Image URL's (img1,img2)</label>
                        <textarea className="form-control textArea" type="text" name="image_urls" defaultValue={profile.product.image_urls}
                          onChange={(e) => setimage_urls(e.target.value)} style={{ "height": "100px !important" }} />
                      </div>
                    </div>
                    <div className="col-md-12 productMaincard">
                      <div className="form-group">
                        <label>Features (feature1,feature2)</label>
                        <textarea className="form-control textArea" type="text" name="features" defaultValue={profile.product.features}
                          onChange={(e) => setfeatures(e.target.value)} style={{ "height": "100px !important" }} />
                      </div>
                    </div>
                    <div className="col-md-12 productMaincard">
                      <div className="form-group">
                        <label>Product Description</label>
                        <textarea className="form-control textArea" type="text" name="description" defaultValue={profile.product.description}
                          onChange={(e) => setdescription(e.target.value)} style={{ "height": "100px !important" }} />
                      </div>
                    </div>
                    <div className="col-md-12 productMaincard">
                      <div className="form-group">
                        <Button className="Button" variant="primary" size="sm" onClick={updateUser} >Update</Button>
                      </div>
                    </div>
                  </div>
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateProducts;
