import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Badge, Accordion, Card, Dropdown, Form, Modal, DropdownButton } from 'react-bootstrap'
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ReactPaginate from 'react-paginate';
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PostAddIcon from '@material-ui/icons/PostAdd';
import FilterListIcon from '@material-ui/icons/FilterList';
import axios from 'axios';
import './index.scss';
import './index.css';
import { useGetAllProductsQuery, useDeleteProductMutation, useUpdateProductMutation, useGetAllProductPriceQuery } from '../../../apis/products';
import Navigation from './Navigation';
import Pagination from './Pagination';
import TableSkeleten from './TableSkeleten';
import PriceSkeleten from './PriceSkeleten';
function Products() {
  const location = useLocation();
  const history = useHistory();
  const user_token = JSON.parse(localStorage.getItem("user_token"));
  const user_id = JSON.parse(localStorage.getItem("user_id"));
  const store_token = JSON.parse(localStorage.getItem("store_token"));
  const store_id = JSON.parse(localStorage.getItem("store_id"));
  const [data, setData] = useState([])
  const [meta, setMeta] = useState([])
  const [httpResponse, httpStatus] = useState([])
  const [isLoading, setLoading] = useState(false)

  const query = new URLSearchParams(location.search).get('query')
  const deleted = new URLSearchParams(location.search).get('blacklisted')
  const deletedb = (deleted == null ? 'false' : deleted)

  let queryurl = '';
  let queryb = '';
  let queryLink = '';
  if (query === null) {
    queryurl = '';
    queryb = '';
    queryLink = '';
  } else {
    queryurl = `/search/`
    queryb = `query=${query}`;
    queryLink = `query=${query}`;
  }

  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 10
  const dataInfo = useGetAllProductsQuery({ page: page, limit: limit, querya: queryurl, queryb: queryb, blacklisted: deletedb }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const dataList = dataInfo?.data?.products
  const pagination = dataInfo?.data?.meta?.pagination

  const [DeleteProduct, deleteresult] = useDeleteProductMutation()
  const [updateProduct, updateresult] = useUpdateProductMutation()

  const [setErrorsJson, setErrors] = useState("")
  const [doanloadErrorDis, doanloadError] = useState("")
  const [isLoadings3, setLoadings3] = React.useState(false)

  function productDownload() {
    setLoadings3(true)
    fetch(`${process.env.REACT_APP_DC_API}/api/marketplace/store_product_lists.json`, {
      method: "GET",
      headers: {
        'Accept': 'application/vnd.api+json; version:3.0',
        'X-User-Id': user_id,
        'X-User-Token': user_token,
        'X-Store-Id': store_id,
        'X-Store-Token': store_token,
        'Content-Type': 'application/json'
      },
    }).then((result) => {
      result.json().then((resp) => {
        if (result.status === 201) {
          setLoadings3(true)
          let domainUrl = resp.url.split('/');
          let dateSplit = domainUrl[domainUrl.length - 2]
          let storeSplit = domainUrl[domainUrl.length - 3]
          let bucketSplit = domainUrl[domainUrl.length - 4]
          axios({
            url: resp.url,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `products-${dateSplit}.csv`);
            document.body.appendChild(link);
            link.click();
            setLoadings3(false)
          });
        }
        else {
          setErrors('error')
          setLoadings3(false)
        }
      })
    })
  }

  const [show, setShow] = useState(false);
  const [getPriceId, setPriceId] = useState("");

  const priceInfo = useGetAllProductPriceQuery({ ItemId: getPriceId }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const priceData = priceInfo?.data?.product?.country_wise_listing_price

  function displayAllPrice(data) {
    setShow(true)
    setPriceId(data.ItemId)
  }
  function handleClose() {
    setShow(false)
  }
  function viewOnDC(data) {
    window.open(`https://www.desertcart.ae/products/${data.ItemId}`, "_blank");
  }
  const [getDeatsilsShow, setDeatsilsShow] = useState(false);
  const [getDbrand_name, setDbrand_name] = useState("");
  const [getDcategory, setDcategory] = useState("");
  const [getDestimated_delivery_days, setDestimated_delivery_days] = useState("");
  const [getDlocation, setDlocation] = useState("");
  const [getDcurrency_code, setDcurrency_code] = useState("");
  const [getDweight_grams, setDweight_grams] = useState("");
  const [getDimage_urls, setDimage_urls] = useState("");
  const [getDfeatures, setDfeatures] = useState("");
  const [getDdescription, setDdescription] = useState("");
  const [getProduct_id, setProduct_id] = useState("");
  const [getdisplayPrice, setdisplayPrice] = useState("");
  const [getsalePrice, setsalePrice] = useState("");
  function detailsClose() {
    setDeatsilsShow(false)
  }
  function displayProductDetails(data) {
    setDeatsilsShow('true')
    setProduct_id(data.product_id)
    setdisplayPrice(data.display_price)
    setsalePrice(data.sale_price)
    setDbrand_name(data.brand_name)
    setDcategory(data.category)
    setDestimated_delivery_days(data.estimated_delivery_days)
    setDlocation(data.location)
    setDcurrency_code(data.currency_code)
    setDweight_grams(data.weight_grams)
    setDimage_urls(data.image_urls)
    setDfeatures(data.features)
    setDdescription(data.description)
  }



  const [getproductUpdateModal, setproductUpdateModal] = useState(false);
  const [getupdateId, setupdateId] = useState("");
  const [getupdateTitle, setupdateTitle] = useState("");
  const [getupdatePrice, setupdatePrice] = useState("");
  const [getupdateCategory, setupdateCategory] = useState("");
  const [getupdateWeight, setupdateWeight] = useState("");
  const [getupdateInstock, setupdateInstock] = useState("");
  const [getupdateEstimateDays, setupdateEstimateDays] = useState("");
  const [getupdateQuantity, setupdateQuantity] = useState("");
  const [getupdateLocation, setupdateLocation] = useState("");
  const [getupdatebrandName, setupdatebrandName] = useState("");
  const [getUpdateImages, setUpdateImages] = useState("");
  const [getupdateFeatures, setupdateFeatures] = useState("");
  const [getupdateDescription, setupdateDescription] = useState("");
  const [getupdateCurrency, setupdateCurrency] = useState("");
  const [getupdateStoreUID, setupdateStoreUID] = useState("");
  const [getupdateOffer, setupdateOffer] = useState("");
  function productUpdateClose() {
    setproductUpdateModal(false)
  }

  useEffect(() => {
    if (updateresult?.isSuccess) {
      setproductUpdateModal(false)
    }
  },
    [updateresult?.isSuccess])
  function productUpdate(data) {
    setproductUpdateModal('true')
    setupdateId(data.updateId)
    setupdateTitle(data.updateTitle)
    setupdatePrice(data.updatePrice)
    setupdateCategory(data.updateCategory)
    setupdateWeight(data.updateWeight)
    setupdateInstock(data.updateInstock)
    setupdateEstimateDays(data.updateEstimateDays)
    setupdateQuantity(data.updateQuantity)
    setupdateLocation(data.updateLocation)
    setupdatebrandName(data.updatebrandName)
    setUpdateImages(data.UpdateImages)
    setupdateFeatures(data.updateFeatures)
    setupdateDescription(data.updateDescription)
    setupdateCurrency(data.updateCurrency)
    setupdateStoreUID(data.updateStoreUID)
    setupdateOffer(data.updateOfferReference)
  }



  const priceCents = getupdatePrice * 100;
  const image_urls = getUpdateImages.toString().split(',');
  const features = getupdateFeatures.toString().split(',');
  const productUpdateData = { "store_parent_uid": getupdateStoreUID, "store_uid": getupdateStoreUID, "title": getupdateTitle, "price_cents": priceCents, "image_urls": image_urls, "description": getupdateDescription, "features": features, "category": getupdateCategory, "weight_grams": getupdateWeight, "list_price": getupdatePrice, "in_stock": getupdateInstock, "estimated_delivery_days": getupdateEstimateDays, "quantity": getupdateQuantity, "location": getupdateLocation, "brand_name": getupdatebrandName, "blacklisted": 'false', "offer_reference": getupdateOffer, "format": 'json' }
  const productsData = { products: [productUpdateData] }


  function viewOnWallmart(data) {
    window.open(`https://www.walmart.com/ip/seort/${data}`, "_blank");
  }

  return (
    <div className="orderStyle productStyle">


      <Modal className="mdlPopup product_updateModal" show={getproductUpdateModal} onHide={productUpdateClose} animation={false} centered>
        <Modal.Header>
          <Modal.Title className="updateHeader">
            Update Product #{getupdateId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 productMaincard">
              <div className="form-group">
                <label>Title</label>
                <input className="form-control" type="text" name="title" defaultValue={getupdateTitle}
                  onChange={(e) => setupdateTitle(e.target.value)} />
              </div>
            </div>
            <div className="col-md-3 productMaincard">
              <div className="form-group">
                <label>Product price ({getupdateCurrency})</label>
                <input className="form-control" type="text" name="list_price" defaultValue={getupdatePrice}
                  onChange={(e) => setupdatePrice(e.target.value)} />
              </div>
            </div>
            <div className="col-md-3 productMaincard">
              <div className="form-group">
                <label>Category</label>
                <input className="form-control" type="text" name="category" defaultValue={getupdateCategory}
                  onChange={(e) => setupdateCategory(e.target.value)} />
              </div>
            </div>
            <div className="col-md-3 productMaincard">
              <div className="form-group">
                <label>Weight in Grams</label>
                <input className="form-control" type="text" name="weight_grams" defaultValue={getupdateWeight}
                  onChange={(e) => setupdateWeight(e.target.value)} />
              </div>
            </div>
            <div className="col-md-3 productMaincard">
              <div className="form-group">
                <label>In Stock (true/false)</label>
                <input className="form-control" type="text" name="in_stock" defaultValue={getupdateInstock}
                  onChange={(e) => setupdateInstock(e.target.value)} />
              </div>
            </div>
            <div className="col-md-3 productMaincard">
              <div className="form-group">
                <label>Estimated Delivery Days</label>
                <input className="form-control" type="text" name="estimated_delivery_days" defaultValue={getupdateEstimateDays}
                  onChange={(e) => setupdateEstimateDays(e.target.value)} />
              </div>
            </div>
            <div className="col-md-3 productMaincard">
              <div className="form-group">
                <label>Quantity</label>
                <input className="form-control" type="text" name="quantity" defaultValue={getupdateQuantity}
                  onChange={(e) => setupdateQuantity(e.target.value)} />
              </div>
            </div>
            <div className="col-md-3 productMaincard">
              <div className="form-group">
                <label>Inventory Location</label>
                <input className="form-control" type="text" name="location" defaultValue={getupdateLocation}
                  onChange={(e) => setupdateLocation(e.target.value)} />
              </div>
            </div>
            <div className="col-md-3 productMaincard">
              <div className="form-group">
                <label>Brand Name</label>
                <input className="form-control" type="text" name="brand_name" defaultValue={getupdatebrandName}
                  onChange={(e) => setupdatebrandName(e.target.value)} />
              </div>
            </div>
            <div className="col-md-12 productMaincard">
              <div className="form-group">
                <label>Image URL's (img1,img2)</label>
                <textarea className="form-control textArea" type="text" name="image_urls" defaultValue={getUpdateImages}
                  onChange={(e) => setUpdateImages(e.target.value)} style={{ "height": "100px !important" }} />
              </div>
            </div>
            <div className="col-md-12 productMaincard">
              <div className="form-group">
                <label>Features (feature1,feature2)</label>
                <textarea className="form-control textArea" type="text" name="features" defaultValue={getupdateFeatures}
                  onChange={(e) => setupdateFeatures(e.target.value)} style={{ "height": "100px !important" }} />
              </div>
            </div>
            <div className="col-md-12 productMaincard">
              <div className="form-group">
                <label>Product Description</label>
                <textarea className="form-control textArea" type="text" name="description" defaultValue={getupdateDescription}
                  onChange={(e) => setupdateDescription(e.target.value)} style={{ "height": "100px !important" }} />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={productUpdateClose}>Close</Button>
          {updateresult.isLoading ? <Button className="Button" variant="info" size="sm" disabled >Updating...</Button> :
            <Button className="Button" variant="info" size="sm" onClick={() => updateProduct(productsData)} >Update</Button>}
        </Modal.Footer>
      </Modal>

      <Modal className="mdlPopup product_detailsModal" show={getDeatsilsShow} onHide={detailsClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Product Details #{getProduct_id}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="PrcBtnLeft"></div>
            <div className="det_Sty"><strong>Display Price:&nbsp;&nbsp; </strong>{getdisplayPrice}</div>
            <div className="det_Sty"><strong>Sale Price:&nbsp;&nbsp; </strong>{getsalePrice}</div>
            <div className="det_Sty"><strong>Product ID:&nbsp;&nbsp; </strong>{getProduct_id}</div>
            <div className="det_Sty"><strong>Brand Name:&nbsp;&nbsp; </strong>{getDbrand_name}</div>
            <div className="det_Sty"><strong>Category:&nbsp;&nbsp; </strong>{getDcategory}</div>
            <div className="det_Sty"><strong>Estimated Delivery Days:&nbsp;&nbsp; </strong>{getDestimated_delivery_days}</div>
            <div className="det_Sty"><strong>Location:&nbsp;&nbsp; </strong>{getDlocation}</div>
            <div className="det_Sty"><strong>Currency:&nbsp;&nbsp; </strong>{getDcurrency_code}</div>
            <div className="det_Sty"><strong>Weight in grams:&nbsp;&nbsp; </strong>{getDweight_grams}</div>
            <div className="det_Sty"><strong>Images:&nbsp;&nbsp; </strong></div><div className="det_Sty">{getDimage_urls}</div>
            <div className="det_Sty"><strong>Features:&nbsp;&nbsp; </strong></div><div className="det_Sty">{getDfeatures}</div>
            <div className="det_Sty"><strong>Description:&nbsp;&nbsp; </strong></div><div className="det_Sty">{getDdescription}</div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal className="mdlPopup product_detailsModal" show={show} onHide={handleClose} animation={false} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ textAlign: "center" }}>Price for all countries #{getPriceId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 Products_tbaleView">
              <table className="table" style={{ textAlign: "center" }}>
                <thead>
                  <tr>
                    <th>Country</th>
                    <th>Currency</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>

                  {priceInfo.isLoading &&
                    <PriceSkeleten />
                  }
                  {priceInfo.isFetching && !priceInfo.isLoading &&
                    <PriceSkeleten />
                  }
                  {priceInfo.isSuccess && priceInfo.status !== 'pending' &&
                    <>
                      {priceData?.map((items, i) => (
                        <tr key={i}>
                          <td>{items.country_code}</td>
                          <td>{items.currency}</td>
                          <td>{items.formatted_price}</td>
                        </tr>
                      ))}
                    </>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="container">
        <div className="row cardList__margin">
          <div className="col-md-12">
            <div className="Table-header">
              <Navigation />
             
              {/* <div className="updateButton">
                {query && <h5 className="listHeadng">Search List</h5>}
                {query === null && <h5 className="listHeadng">Products List</h5>}
              </div> */}

              <div className="dFlex-sb">
                <div className="dFlex">
                  {store_id != 2205 &&
                    <div className="dFlex hdMob">
                      {isLoadings3 ? <Button size="sm" variant="warning" className="mr10" disabled>
                        <span className="hdMob dFlex">Please wait...</span>
                      </Button> : <Button size="sm" variant="warning" className="mr10" onClick={productDownload}>
                        <span className="hdMob dFlex">Export List</span>
                      </Button>}
                      {setErrorsJson === 'error' && <small className="errorHeadng">Error!</small>}
                    </div>}
                  <div className="mr10 hdMob">
                    <Button size="sm" variant="outline-secondary" disabled>List:&nbsp;{pagination?.total_count}</Button>
                  </div>
                  <div className="mr10">
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-secondary" size="sm" id="dropdown-basic" className="dFlex">
                        Size {limit}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ "minWidth": "20px" }}>
                        <Dropdown.Item as={Link} to={{ pathname: "/products", search: `?${queryLink}&size=20&page=1&blacklisted=${deletedb}` }}>20</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: "/products", search: `?${queryLink}&size=40&page=1&blacklisted=${deletedb}` }}>40</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: "/products", search: `?${queryLink}&size=60&page=1&blacklisted=${deletedb}` }}>60</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: "/products", search: `?${queryLink}&size=80&page=1&blacklisted=${deletedb}` }}>80</Dropdown.Item>
                        <Dropdown.Item as={Link} to={{ pathname: "/products", search: `?${queryLink}&size=100&page=1&blacklisted=${deletedb}` }}>100</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <Pagination pagination={pagination} page={page} limit={limit} queryLink={queryLink} deletedb={deletedb} />
              </div>
            </div>

            <div className="orders_tbaleView">
              <table className="table">
                <thead>
                  <tr>
                    <th><FilterListIcon /></th>
                    <th>SKU/UID</th>
                    <th>Images</th>
                    <th>Qty.</th>
                    <th>Display Price</th>
                    <th>Title</th>
                    <th className="sticky-column">Status</th>
                  </tr>
                </thead>
                <tbody>

                  {dataInfo.isLoading &&
                    <TableSkeleten />
                  } 
                  {!dataInfo.isLoading &&
                    <>
                      {dataList?.map((item, i) =>
                        <tr key={i}>
                          <td className="table_sizeTD_Manage">
                            <div className="actionBDiv">
                              {[DropdownButton].map((DropdownType, idx) => (
                                <DropdownType className="dropBtnF" size="sm" variant="secondary">
                                  <Dropdown.Item eventKey="2" onClick={() => displayAllPrice({ "ItemId": item.id })}>View price in all countries</Dropdown.Item>
                                  <Dropdown.Item eventKey="4" onClick={() => viewOnDC({ "ItemId": item.id })}>View product on DC</Dropdown.Item>
                                </DropdownType>
                              ))}
                            </div>
                          </td>
                          <td className="table_sizeTD_Manage ProView_details">
                            {store_id === 2 ?
                              <a href={`https://www.walmart.com/ip/seort/${item.store_uid}`} target="_blank">{item.store_uid}</a>
                              :
                              item.store_uid
                            }
                          </td>
                          <td className="table_sizeTD">
                            <div className="cardImage">
                              <img className="cardImages" src={item.image_urls[0] ? item.image_urls[0] : '/image-not-found.png'} width="120px" />
                            </div></td>
                          <td className="table_sizeTD_Manage ProView_details">
                            <div className="stchLeft">
                              <div className="poridtc_Qty">{item.quantity}</div>
                            </div>
                          </td>

                          <td className="table_sizeTD ProView_details">
                            {item.display_price.formatted_price}
                          </td>
                          <td className="ProView_details">
                            <div className="product_TitleWrap">
                              {item.title}
                            </div>
                          </td>
                          <td className="table_sizeTD_Manage ProView_details sticky-column">
                            <Button size="sm" variant="info" className="status-buttons button-margin" onClick={() => displayProductDetails({
                              "product_id": item.id,
                              "display_price": item.display_price.formatted_price,
                              "sale_price": item.sale_price.formatted,
                              "brand_name": item.brand_name,
                              "category": item.category,
                              "estimated_delivery_days": item.estimated_delivery_days,
                              "location": item.location,
                              "currency_code": item.sale_price.currency_code,
                              "weight_grams": item.weight_grams,
                              "image_urls": item.image_urls,
                              "features": item.features,
                              "description": item.description
                            })} >
                              <small>Details</small>
                            </Button>
                            {!item.deleted && <Button size="sm" variant="danger" className="status-buttons button-margin" onClick={() => DeleteProduct({ "ItemId": item.id })} >
                              <small>Delete</small>
                            </Button>}
                            {item.deleted && <Button size="sm" variant="danger" className="status-buttons button-margin" disabled>
                              <small>Deleted</small>
                            </Button>}
                            {!item.deleted &&
                              <Button size="sm" variant="warning" className="status-buttons button-margin" onClick={() => productUpdate({
                                "updateId": item.id,
                                "updateTitle": item.title,
                                "updatePrice": item.sale_price.plain,
                                "updateCategory": item.category,
                                "updateWeight": item.weight_grams,
                                "updateInstock": item.in_stock,
                                "updateEstimateDays": item.estimated_delivery_days,
                                "updateQuantity": item.quantity,
                                "updateLocation": item.location,
                                "updatebrandName": item.brand_name,
                                "UpdateImages": item.image_urls,
                                "updateFeatures": item.features,
                                "updateDescription": item.description,
                                "updateCurrency": item.sale_price.currency_code,
                                "updateStoreUID": item.store_uid,
                                "updateOfferReference": item.offer_reference
                              })} >
                                <small>Update</small>
                              </Button>
                            }
                            {!item.deleted &&
                              <>
                                {item.quantity === 0 &&
                                  <Button size="sm" variant="secondary" className="status-buttons" disabled>
                                    <small>Offline</small>
                                  </Button>
                                }
                              </>
                            }
                          </td>
                        </tr>
                      )}
                    </>
                  }
                </tbody>
              </table>
              {dataInfo.isSuccess && dataInfo.status !== 'pending' &&
                <>
                  {pagination?.total_count === 0 &&
                    <div className="row">
                      <div className="col-md-12">
                        <div className="storeCard emptyCard">
                          <div>
                            <h5 className="listHeadng emptyCard_Text">No data found!
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>}
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Products;